import { ChangeEvent, ClipboardEvent, ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { IOtpInputProps } from './otpInput.models';
import { InputContainer, Input, ErrorMessage } from './otpInput.style';

const OtpInput = forwardRef<HTMLInputElement, IOtpInputProps>(
  (
    { number, errorMessage, showError, forcedDisabled, onChange, setIsError }: IOtpInputProps,
    ref: ForwardedRef<any>
  ) => {
    const numberOfInputs = number > 1 ? number : 2;
    const inputs = [];
    const [otpValues, setOtpValues] = useState<string[]>([]);

    // TODO: FIX OTP RETRIEVAL
    // const [returnedCode, setReturnedCode] = useState<string>('');

    // useEffect(() => {

    //   const getOtp = async (ac: AbortController) => {
    //     const otp = await navigator.credentials
    //     .get({
    //       //@ts-ignore
    //       otp: { transport: ['sms'] },
    //       signal: ac.signal,
    //     });
    //     //@ts-ignore
    //     if (otp) setReturnedCode(otp.code);
    //   }

    //   if ('OTPCredential' in window) {
    //     const ele = document.querySelector('div.otpContainer');
    //     const otpInputs = ele?.querySelectorAll('input');
    //     if (!otpInputs) return;
    //     const ac = new AbortController();
    //     if('credentials' in navigator){
    //       getOtp(ac).then(() => {
    //         //@ts-ignore
    //         const code = returnedCode.split('');
    //         alert('entered')
    //         otpInputs?.forEach((currentValue, currentIndex) => {
    //           if (currentIndex < code.length && currentIndex < numberOfInputs) {
    //             otpInputs[currentIndex].value = returnedCode[currentIndex];
    //           }
    //         });
    //         setOtpValues([...code]);
    //       }).then(() => {alert('entered part 2')}).catch((err) => alert(err));
    //     }
    //     else {
    //       alert('OUTSIDE')
    //     }
    //   }
    // }, [window, returnedCode]);

    const tabChange = (val: number) => {
      const ele = document.querySelector('div.otpContainer');
      const otp = ele?.querySelectorAll('input');
      if (otp) {
        if (otp[val - 1].value !== '' && val < otp.length) {
          otp[val].focus();
        } else if (otp[val - 1].value === '' && val > 1) {
          otp[val - 2].focus();
        }
      }
    };

    const digitValidate = (val: number) => {
      const ele = document.querySelector('div.otpContainer');
      const otp = ele?.querySelectorAll('input');
      if (otp) {
        otp[val].value = otp[val].value.replace(/[^0-9]/g, '');
      }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
      const values = otpValues;
      values[index] = event.target.value;
      setOtpValues([...values]);
      
      if (setIsError) setIsError();
    };

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
      const ele = document.querySelector('div.otpContainer');
      const otp = ele?.querySelectorAll('input');
      const pastedInput = event.clipboardData.getData('text').replace(/[^0-9]/g, '').split('');

      otp?.forEach((currentValue, currentIndex) => {
        const values = otpValues;
        if (currentIndex < pastedInput.length) {
          otp[currentIndex].value = pastedInput[currentIndex];
          values[currentIndex] = pastedInput[currentIndex];
        }
        setOtpValues([...values]);
      });

    };

    const checkIsDisabled = (inputIndex: number) => {
      if (forcedDisabled) return true;
      if (inputIndex === 0) return false;
      const ele = document.querySelector('div.otpContainer');
      const otp = ele?.querySelectorAll('input');
      const prevOtpValue = otp?.[inputIndex - 1].value;
      return !prevOtpValue;
    };

    useEffect(() => {
      if (otpValues.filter(Boolean).length === numberOfInputs) {
        const otp = otpValues?.join('') || '';
        if (onChange) onChange(otp);
      }
    }, [otpValues]);

    useEffect(() => {
      if (showError) {
        const ele = document.querySelector('div.otpContainer');
        const otp = ele?.querySelectorAll('input');
        otp?.forEach((currentValue, currentIndex) => {
          otp[currentIndex].value = '';
        });
        setOtpValues([]);
      }
    }, [showError]);

    for (let i = 0; i < numberOfInputs; i += 1) {
      inputs.push(
        <Input
          autoFocus={i === 0}
          type="text"
          autoComplete="one-time-code"
          inputMode="numeric"
          required
          maxLength={1}
          disabled={checkIsDisabled(i)}
          onKeyUp={() => tabChange(i + 1)}
          onInput={() => digitValidate(i)}
          onChange={(event) => handleChange(event, i)}
          onPaste={(event) => handlePaste(event)}
          value={otpValues[i]}
          ref={ref}
        />
      );
    }

    return (
      <>
        <InputContainer className="otpContainer">{inputs}</InputContainer>
        {showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </>
    );
  }
);

export default OtpInput;
