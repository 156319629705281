import { breakpoints, colors, fonts } from '@vfit/shared/themes';
import styled from 'styled-components';

export const GalleryImageContainer = styled.div`
  width: 100%;
  min-height: 553px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media (min-width: ${breakpoints.tablet}) {
  }

  @media (min-width: ${breakpoints.desktop}) {
  }
`;

export const ThumbnailContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  align-items: center;

  div {
    height: 77px;
    width: 77px;
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.tablet}) {
  }

  @media (min-width: ${breakpoints.desktop}) {
  }
`;

export const SelectedImageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 32px;
  flex: 10;

  @media (min-width: ${breakpoints.tablet}) {
  }

  @media (min-width: ${breakpoints.desktop}) {
  }
`;

export const SwiperContainer = styled.div``;

export const IconContainer = styled.div`
  position: absolute;
  bottom: 80px;
  right: 10px;
  width: 34px;
  height: 34px;
  border: 1px solid ${colors.$bebebe};
  border-radius: 100%;
  z-index: 1;
  text-align: center;
  line-height: 27px;
`;
export interface IArrowButton {
  rotation: number;
  color: string;
  arrowWidth?: number;
  arrowHeigth?: number;
}

export const ArrowButton = styled.div<IArrowButton>`
  margin: 0 auto;
  transform: rotate(${({ rotation }) => `${rotation}deg`});
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ color }) => color};
  border-radius: 100%;
  height: 36px !important;
  width: 36px !important;
  user-select: none; 

  svg {
    width: ${({ arrowWidth }) => (arrowWidth ? `${arrowWidth}px` : '9px')};
    height: ${({ arrowHeigth }) => (arrowHeigth ? `${arrowHeigth}px` : '16px')};

    path {
      stroke: ${({ color }) => color};
    }
  }

  &:hover {
    border: 1px solid ${colors.$262626};
    path {
      stroke: ${colors.$262626};
    }
  }
`;

export const BoxImage = styled.div<{ selected: boolean }>`
  border: ${({ selected, color }) =>
    selected ? `2px solid ${colors.$0096AD}` : `1px solid ${colors.$bebebe}`};
  border-radius: 10px;
  overflow: hidden;
`;
