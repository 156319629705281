import styled from 'styled-components';

export const LoadingContent = styled.div`
  position: absolute;

  div {
    svg {
      width: 20px;
    }
  }
`;
