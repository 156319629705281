import { breakpoints } from '@vfit/shared/themes';
import styled from 'styled-components';

export const PillsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  row-gap: 10px;
  margin-top: 12px;

  & > div {
    margin-right: 8px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 24px;
  }
`;
