import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';
import { ICSSProps } from './codeInput.models';

export const ErrorMessage = styled.p`
  position: relative;
  display: flex;
  justify-content: center;
  animation: fadein 2s;
  margin-block: 0;
  margin-inline: 0;
  margin-bottom: -2px !important;
  color: ${colors.$bd0000};
  ${pxToCssFont(14, 18)}
  font-family: ${fonts.regular};
  text-align: center;

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: -8px !important;
  }
`;
export const FieldsetContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const Fieldset = styled.div<ICSSProps>`
  width: 500px;
  height: 57px;
  position: relative;
  margin: 0;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  transition: border 2s;
  background: transparent;
  ${(props) => {
    return props.isDisabled
      ? `border-bottom: 1px solid #BEBEBE`
      : `border-bottom: 1px solid #BEBEBE`;
  }};

  .wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 24px;
  }

  ${(props) => (props.isDisabled ? '&:hover {cursor: not-allowed}' : '')}
`;

// div for inputs
export const Field = styled.input<ICSSProps>`
  background-color: transparent;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 27px;
  outline: 0;
  border: 0;
  text-overflow: ellipsis;
  font-family: ${fonts.exbold};
  ${pxToCssFontSize(24)}
  text-align: center;

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.$262626};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: auto;
  }
`;

