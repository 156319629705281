import React, { SVGProps, memo } from 'react';

const ZoomPlus = (props: SVGProps<SVGElement>) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Zoom_in_ic">
      <path
        id="Vector"
        d="M4.64111 7.94531H11.4127"
        stroke="#111111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M8.02686 4.5791V11.3115"
        stroke="#111111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="Search_ic 2">
        <path
          id="Vector_3"
          d="M8.02698 14.6776C11.7668 14.6776 14.7986 11.6634 14.7986 7.94524C14.7986 4.22707 11.7668 1.21289 8.02698 1.21289C4.28712 1.21289 1.25537 4.22707 1.25537 7.94524C1.25537 11.6634 4.28712 14.6776 8.02698 14.6776Z"
          stroke="#111111"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M12.9888 12.8779L19.6358 19.4865"
          stroke="#111111"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default memo<SVGProps<SVGAElement>>(ZoomPlus);
