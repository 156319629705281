import { ComponentPropsWithoutRef, forwardRef } from 'react';
import TextInput from './TextInput/textInput';
import AddressInput from './AddressInput/addressInput';
import SelectInput from './SelectInput/selectInput';
import MaskInput from './MaskInput/maskInput';
import TextAreaInput from './TextAreaInput/textAreaInput';
import OtpInput from './OtpInput/otpInput';
import SearchSelect from './SearchSelect/searchSelect';
import CodeInput from './CodeInput/codeInput';

interface FormProps extends ComponentPropsWithoutRef<'form'> {}

const Form = forwardRef<HTMLFormElement, FormProps>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <form ref={ref} {...rest}>
      {children}
    </form>
  );
});

// eslint-disable-next-line prefer-object-spread
const CompoundForm = Object.assign({}, Form, {
  TextInput,
  TextAreaInput,
  AddressInput,
  SelectInput,
  SearchSelect,
  MaskInput,
  OtpInput,
  CodeInput
});

export default CompoundForm;
