import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';
import { IPassionCardTopImage } from './passionCard.models';

export const PassionCardContainer = styled.div<{ isApp?: boolean; iconSize?: number }>`
  display: flex;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: ${({ isApp }) => `${isApp ? '6px' : '20px'}`};
  flex-direction: column;
  margin-left: 20px;
  cursor: pointer;

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 0;
  }
`;

export const PassionCardTopImage = styled.div<IPassionCardTopImage>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: 150px;
  border-radius: ${({ isApp }) => `${isApp ? '6px 6px 0 0' : '20px 20px 0 0'}`};
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (min-width: ${breakpoints.tablet}) {
    height: ${({ large }) => (large ? '400px' : '200px')};
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: ${({ large }) => (large ? '400px' : '200px')};
  }
`;

export const PassionCardTopImageJiustifyContentBox = styled.div`
  width: calc(100% - 48px);
  margin: 0 auto;
  height: calc(100% - 38px);
  margin-top: 22px;
  position: relative;

  & > div,
  & > h2 {
    position: absolute;
    left: 0;
  }

  & > div {
    top: 0;
  }

  & > h2 {
    bottom: 0;
  }
`;

export const TitleAboveImg = styled.h2`
  ${pxToCssFont(28, 36)}
  margin: 0;
  color: white;
  z-index: 1;
`;

export const PassionCardBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: ${colors.$ffffff};
  backdrop-filter: blur(40px);
  min-height: 280px;
`;

export const PassionCardBottomContainer = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const PassionCardBottomContainerText = styled.div`
  margin-top: 32px;

  h3 {
    margin: 0;
    font-family: ${fonts.exbold};
    color: ${colors.$0d0d0d};
    font-weight: 400;
    ${pxToCssFont(30, 38)}
  }

  p {
    margin: 0;
    margin-top: 24px;
    font-family: ${fonts.regular};
    color: ${colors.$0d0d0d};
    font-weight: 400;
    ${pxToCssFontSize(18)}
  }

  @media (min-width: ${breakpoints.tablet}) {
    p {
      ${pxToCssFontSize(18)}
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    h3 {
      ${pxToCssFont(36, 45)}
    }

    p {
      ${pxToCssFontSize(18)}
    }
  }
`;

export const TextContainer = styled.div<{ lineHeight?: number }>`
  div {
    margin: 0;
    margin-top: 24px;
    ${({ lineHeight }) => pxToCssFont(18, lineHeight || 22)}
    color: ${colors.$262626};
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin: 0;
      margin-top: 24px;
    }
  }
`;

export const PassionAction = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
