import type { SVGProps } from 'react';
import { memo } from 'react';

const SelectedIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 0.75L9 9.25L0.5 0.75"
        stroke="#333"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default memo<SVGProps<SVGSVGElement>>(SelectedIcon);
