import styled from 'styled-components';
import { colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import { ICSSSelectInputProps } from './selectInput.models';

export const FillBorder = styled.div`
  height: 20px !important;
  background: transparent;
  border-color: ${colors.$bebebe};
  border-style: solid;
  border-width: 0 1px;
  margin-top: -10px !important;
`;

export const Wrapper = styled.div`
  position: relative;
  background-color: ${colors.$ffffff};
  border-radius: 6px;
`;

export const Suggestions = styled.ul<ICSSSelectInputProps>`
  ${(props) => (props.isError ? 'display: none;' : '')}
  z-index: 3;
  max-height: ${(props) => (props.suggestionsMaxHeight ? props.suggestionsMaxHeight : '208px')};
  overflow-y: auto;
  overflow-x: unset;
  position: absolute;
  top: 0;
  margin-top: -10px;
  width: 100%;
  width: fill-available;
  background-color: ${colors.$ffffff};
  padding: 0;
  border-left: 1px solid ${colors.$bebebe};
  border-right: 1px solid ${colors.$bebebe};
  border-bottom: 1px solid ${colors.$bebebe};
  border-radius: 0 0 5px 5px;

  li:first-child {
    padding-top: 16px;
  }

  li:last-child {
    padding-bottom: 16px;
  }
`;

export const SuggestionItem = styled.li`
  list-style: none;
  padding: 8px 20px;
  color: ${colors.$7e7e7e};
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(18, 24)}
  text-align: left;

  &:hover {
    cursor: pointer;
    background-color: #ccc;
  }
`;

