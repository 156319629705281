export enum IGradientImage {
  GRADIENT_TOP_35 = 'linear-gradient(180deg, rgba(0, 0, 0, 0.35) 4.87%, rgba(0, 0, 0, 0) 50%)',
  GRADIENT_TOP_30 = 'linear-gradient(180deg, rgba(39, 39, 39, 0.3) 28.03%, rgba(0, 0, 0, 0) 84.9%)',
  GRADIENT_TOP_LEFT = 'linear-gradient(180.79deg, rgba(0, 0, 0, 0.2) 0.4%, rgba(0, 0, 0, 0) 78.67%), linear-gradient(90deg, rgba(10, 10, 10, 0.6) 10.81%, rgba(0, 0, 0, 0) 51.74%)',
  GRADIENT_LEFT = 'linear-gradient(90deg, rgba(62, 62, 62, 0.6) 17.03%, rgba(0, 0, 0, 0) 83.87%)',
  GRADIENT_BOTTOM_70 = 'linear-gradient(360deg, rgba(0, 0, 0, 0.7) 6.4%, rgba(0, 0, 0, 0) 66.36%)',
  GRADIENT_BOTTOM_40 = 'linear-gradient(1.14deg, rgba(0, 0, 0, 0.4) 11.28%, rgba(0, 0, 0, 0) 75.72%)',
  GRADIENT_FULL = 'linear-gradient(0deg, rgba(0, 0, 0, 40%), rgba(0, 0, 0, 40%))',
  GRADIENT_TOP_10 = 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.40) 33.99%, rgba(0, 0, 0, 0.00) 79.35%)'
}
