import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgLocateMeSystem = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M156 96.25c0 33.125-26.875 60-60 60s-60-26.875-60-60 26.875-60 60-60 60 26.875 60 60ZM20 96.25h32M140 96.25h32M96 172.25v-32M96 52.25v-32"
      transform="scale(.125)"
      fill="none"
      strokeWidth={8}
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#e60000"
      strokeOpacity={1}
      strokeMiterlimit={4}
    />
  </svg>
);

export default memo<SVGProps<SVGSVGElement>>(SvgLocateMeSystem);
