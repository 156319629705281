import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

// TODO: [refactoring] height
export const CoveredFirstSlide = styled.div`
  height: 500px;
  margin-bottom: 20px;
  position: relative;
`;

export const SliderCarouselContainer = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 120px;
  }
`;

