import { Children, useEffect, useRef } from 'react';
import { useDeviceType } from '@vfit/shared/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ImageDivider, CustomText, CardInfo } from '@vfit/shared/atoms';
import { EffectCards, FreeMode, Keyboard, Mousewheel, Pagination } from 'swiper';
import { Fade } from 'react-awesome-reveal';
import { AppMobile } from '@vfit/shared/data-access';
import {
  CardInfoProductContainer,
  CardInfoProductContent,
  DescriptionContainer,
  DisclaimerContainer,
} from './cardInfoProduct.style';
import { getSlideSize, updateAllHeight } from './cardInfoProduct.utils';
import { ICardInfoProductProps } from './cardInfoProduct.model';

const CardInfoProduct = ({
  topText,
  containerStyle,
  newCards,
  divider,
  disclaimer,
  onClickCardAction,
  description,
}: ICardInfoProductProps) => {
  const { isMobile, isTablet } = useDeviceType();
  const cardInfoElement = useRef<HTMLDivElement>(null);

  const setHeights = () => {
    if (cardInfoElement.current) {
      updateAllHeight(cardInfoElement.current);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setHeights();
    }, 100);
  }, [newCards]);

  const swiperProduct = () => (
    <Swiper
      slidesPerView="auto"
      effect="slide"
      cardsEffect={{
        rotate: true,
        slideShadows: false,
      }}
      mousewheel={{
        forceToAxis: true,
      }}
      spaceBetween={33}
      freeMode={false}
      centeredSlides={isMobile}
      keyboard
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, FreeMode, Mousewheel, Keyboard, EffectCards]}
    >
      {Children.toArray(
        newCards?.map((card, index) => {
          const appTrue = '?app=true';
          const url = AppMobile.checkIsApp() ? card?.action?.url + appTrue : card?.action?.url;
          let marginLeft = index === 0 ? 80 : 0;
          if (index === 0 && isMobile) marginLeft = 0;
          const customSize = getSlideSize(isMobile, isTablet);
          return (
            <SwiperSlide
              style={{
                marginLeft,
                borderRadius: '0px',
                ...customSize,
              }}
            >
              <CardInfoProductContent>
                <CardInfo
                  icon={card?.image || index + 1}
                  title={card?.title || ''}
                  description={card?.description || ''}
                  actionText={card.action?.title || ''}
                  actionClick={() => onClickCardAction?.({ ...card.action, url }, card?.key)}
                  frontLabel={card?.frontLabel}
                  actionType={card?.action?.type}
                  actionStyle={card?.action?.style}
                  actionIsBlank={card?.action?.isBlank}
                  actionNavigationUrl={card?.actionNavigationUrl}
                />
              </CardInfoProductContent>
            </SwiperSlide>
          );
        })
      )}
    </Swiper>
  );

  return (
    <>
      <DescriptionContainer>
        {topText && <CustomText text={topText} />}
        {description && <CustomText text={description} />}
      </DescriptionContainer>
      <CardInfoProductContainer style={{ ...containerStyle }} ref={cardInfoElement}>
        {swiperProduct()}
      </CardInfoProductContainer>
      {disclaimer && (
        <DisclaimerContainer>
          <CustomText text={disclaimer} lineHeight={22} size={16} textAlign="left" />
        </DisclaimerContainer>
      )}
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider image={divider?.image} alt={divider.text || ''} />
        </Fade>
      )}
    </>
  );
};

export default CardInfoProduct;

