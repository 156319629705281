import { useEffect, useRef, useState } from 'react';
import { useIntersectionObserver } from '@vfit/shared/hooks';
import { IIntersectiveSpace } from './intersectiveSpace.models';

const IntersectiveSpace = (props: IIntersectiveSpace) => {
  const {
    intersectiveButtonId,
    intersectivePointerId,
    intersectiveCards,
    defaultMarginBottom,
    updateTextStyle,
    updateArrowStyle,
  } = props;
  const intersectiveCardRef = useRef<HTMLDivElement | null>(null);
  const stepperCardDerived = document.getElementById(intersectiveButtonId)?.getBoundingClientRect();
  const stepperCardDeriverEntry = document
    .getElementById(intersectivePointerId)
    ?.getBoundingClientRect();
  const [maxWidth, setMaxWidth] = useState(0);
  const [lastWidth, setLastWidth] = useState(0);
  const [options, setOptions] = useState({
    threshold: 1,
    rootMargin: `0px 0px 0px 0px`,
  });
  const entry = useIntersectionObserver(intersectiveCardRef, options);
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    // Error on  rootmargin
    // setOptions((prevState) => ({
    //   ...prevState,
    //   rootMargin: `-${
    //     stepperCard?.bottom || props.defaultMarginBottom || 0
    //   }px 0px 0px 0px`,
    // }));
    setOptions((prevState) => ({
      ...prevState,
      rootMargin: `-${defaultMarginBottom || 0}px 0px 0px 0px`,
    }));
  }, [stepperCardDerived?.bottom]);

  useEffect(() => {
    if (intersectiveCards?.length > 0) {
      const maxStateWidth = Math.max(
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...intersectiveCards?.map((step: { cardStyle: { width: any } }) =>
          Number(step?.cardStyle?.width)
        ),
        0
      );
      const lastItemWidth = Number([...intersectiveCards].shift()?.cardStyle?.width);
      setMaxWidth((_) => maxStateWidth);
      setLastWidth((_) => lastItemWidth);
    }
  }, [intersectiveCards]);
  const changeColor = (withoutVisibility: boolean = true) => {
    const stepperCard = document.getElementById(intersectiveButtonId)?.getBoundingClientRect();
    const stepperCardEntry = document
      .getElementById(intersectivePointerId)
      ?.getBoundingClientRect();
    const isIntersected =
      stepperCard &&
      stepperCardEntry &&
      ((stepperCardEntry.y >= 0 && stepperCardEntry.y <= stepperCard.bottom) ||
        stepperCardEntry.y < 0 ||
        (withoutVisibility ? !withoutVisibility : !isVisible)) &&
      stepperCard.width + stepperCard.x >= stepperCardEntry.x &&
      (!withoutVisibility ? !isVisible : withoutVisibility);
    updateTextStyle(isIntersected);
    updateArrowStyle(isIntersected && stepperCardEntry && stepperCardEntry.x - stepperCard.x <= 0);
  };

  useEffect(() => {
    changeColor(false);
  }, [stepperCardDerived, stepperCardDeriverEntry, isVisible]);

  useEffect(() => {
    window.addEventListener('resize', (_) => changeColor());
    return () => {
      window.removeEventListener('resize', (_) => changeColor());
    };
  }, []);

  return (
    <div
      style={{
        height: '0px',
        width: `${maxWidth}px`,
        marginLeft: `-${(maxWidth - lastWidth) / 2}px`,
      }}
      ref={intersectiveCardRef}
      id={intersectivePointerId}
    />
  );
};
export default IntersectiveSpace;

