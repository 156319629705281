import styled from 'styled-components';
import { breakpoints, colors } from '@vfit/shared/themes';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  max-width: 327px;
  margin: 64px auto;
  background-color: ${colors.$ffffff};

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 680px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-width: 1062px;
    margin: 120px auto;
    flex-direction: row;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  div {
    margin: 0;
    text-align: center;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 50%;

    div {
      text-align: left;
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  @media (min-width: ${breakpoints.desktop}) {
    width: 50%;
  }
`;

