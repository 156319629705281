import { ForwardedRef, forwardRef, useEffect } from 'react';
import { ErrorIcon } from '@vfit/shared-icons';
import { ITextInputProps } from '@vfit/shared/models';
import { Fieldset, Legend, Field, ErrorMessage } from './textInput.style';

/** TEXT INPUT DOCS
 * @description This component aims to display a customized input component.
 * @param props -
 * - label : string --> (REQUIRED) legend on top of Input
 * - icon : JSX.Element --> icon to be rendered on the right
 * - onIconClick : () => void | undefined --> handler for managing icon click
 * - error : string --> to manage error state and message
 * - disabled : boolean --> (DEFAULT FALSE) to manage disabled input
 * - rest... --> remaining props from Input Text default component
 * @author Andrea
 */
const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  (props: ITextInputProps, ref: ForwardedRef<any>) => {
    const {
      label,
      icon,
      onIconClick,
      error,
      disabled = false,
      hidden,
      isErrorMessageDisabled,
      isErrorIconDisabled,
      onErrorInput,
      ...rest
    } = props;

    useEffect(() => {
      if (!isErrorMessageDisabled && error && error !== ' ' && onErrorInput) {
        onErrorInput();
      }
    }, [error]);

    return (
      <>
        {!hidden && (
          <div>
            <Fieldset error={error} isDisabled={disabled} className="textInputFieldset">
              <div className="wrapper">
                <Field
                  disabled={disabled}
                  isDisabled={disabled}
                  type="text"
                  value={props.value}
                  ref={ref}
                  {...rest}
                />
                <Legend error={error}>{label}</Legend>
                {(icon || error) && (
                  <span className="wrapper-icon">
                    <button type="button" className="icon" onClick={onIconClick}>
                      {error && !isErrorIconDisabled ? <ErrorIcon /> : icon}
                    </button>
                  </span>
                )}
              </div>
            </Fieldset>
          </div>
        )}
        {!isErrorMessageDisabled && error && error !== ' ' && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
  }
);

export default TextInput;
