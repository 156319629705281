import React from 'react';
import { CustomText } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { ISliderCarousel } from '@vfit/shared/models';
import { SlideCarousel } from '@vfit/shared/components';
import { CoveredFirstSlide, SliderCarouselContainer } from './sliderCarousel.style';
import { DEFAULT_SIZE, getSlideSize } from './sliderCarousel.utils';
import SwiperCards from './components/SwiperCards/swiperCards';

const SliderCarousel = ({
  topText,
  slides,
  isCovered,
  isExperiences,
  isPromo,
  disableCursor,
  forceTextTop,
  anchorScroll,
  containerStyle,
  showCase,
  onTrack,
  SlideCarouselComponent = SlideCarousel,
}: ISliderCarousel) => {
  const { isMobile, isTablet } = useDeviceType();
  const isCoveredMobile = isMobile && isCovered;
  const carouselSlides = isCoveredMobile ? [...slides.slice(1)] : slides ? [...slides] : [];
  const mobileTextBottom = slides?.[0]?.positionTextTop === 'false';

  const coveredFirstSlideMobile = () => (
    <CoveredFirstSlide>
      <SlideCarouselComponent
        onTrack={onTrack}
        showCase={showCase}
        position={0}
        slide={{
          ...slides[0],
          ...DEFAULT_SIZE.large_mobile,
        }}
        defaultSize={DEFAULT_SIZE.large_mobile}
        textBottom={mobileTextBottom}
        coveredText={isCovered}
        zoomIn={!isMobile}
        forcePlayVideo={isCoveredMobile}
        isEnabledOnClickAction
      />
    </CoveredFirstSlide>
  );

  const orderedCards = () => (
    <div>
      {carouselSlides?.map((slide, index) => {
        const customSize = getSlideSize(
          index,
          isCovered || false,
          isExperiences || false,
          isPromo || false,
          isMobile,
          isTablet
        );
        return (
          <div
            style={{
              ...customSize,
              position: 'relative',
              marginBottom: 32,
            }}
          >
            <SlideCarouselComponent
              onTrack={onTrack}
              position={index}
              showCase={showCase}
              slide={{
                ...slide,
                ...customSize,
              }}
              zoomIn={!!(!isMobile || isCovered)}
              defaultSize={customSize}
              coveredText={isCovered}
            />
          </div>
        );
      })}
    </div>
  );

  const checkCards = () => {
    if (isMobile && isPromo) return orderedCards();
    return (
      <SwiperCards
        onTrack={onTrack}
        showCase={showCase}
        carouselSlides={carouselSlides}
        isCovered={isCovered}
        isExperiences={isExperiences}
        disableCursor={disableCursor}
        isPromo={isPromo}
        forceTextTop={forceTextTop}
        SlideCarouselComponent={SlideCarouselComponent}
      />
    );
  };

  return (
    <>
      {topText && slides?.length > 0 && <CustomText text={topText} anchorScroll={!!anchorScroll} />}
      <SliderCarouselContainer
        style={{ ...containerStyle }}
        id={anchorScroll ? 'anchorScroll' : ''}
      >
        {isCoveredMobile && coveredFirstSlideMobile()}
        {checkCards()}
      </SliderCarouselContainer>
    </>
  );
};

export default SliderCarousel;
