import {
  ICardDirectionType,
  ICardStyleType,
  IMainCardsStyle,
  IMainCardStyle,
  IStepCard,
} from '@vfit/shared/models';

export const CARDS_STYLE: IMainCardsStyle = {
  MOBILE: {
    HIDDEN: {
      zIndex: 0,
      top: 54,
      opacity: 0,
      width: 237,
      height: 492,
    },
    FIRST: {
      zIndex: 1,
      top: 66,
      opacity: 0.9,
      width: 267,
      height: 492,
    },
    SECOND: {
      zIndex: 2,
      top: 78,
      opacity: 0.9,
      width: 291,
      height: 492,
    },
    THIRD: {
      zIndex: 3,
      top: 90,
      width: 327,
      height: 492,
    },
  },
  TABLET: {
    HIDDEN: {
      zIndex: 0,
      top: 54,
      opacity: 0,
      width: 337,
      height: 737,
    },
    FIRST: {
      zIndex: 1,
      top: 66,
      opacity: 0.9,
      width: 386,
      height: 737,
    },
    SECOND: {
      zIndex: 2,
      top: 78,
      opacity: 0.9,
      width: 410,
      height: 737,
    },
    THIRD: {
      zIndex: 3,
      top: 90,
      width: 446,
      height: 737,
    },
  },
  DESKTOP: {
    HIDDEN: {
      zIndex: 0,
      top: 109,
      opacity: 0,
      width: 420,
      height: 737,
    },
    FIRST: {
      zIndex: 1,
      top: 121,
      opacity: 0.9,
      width: 450,
      height: 737,
    },
    SECOND: {
      zIndex: 2,
      top: 133,
      opacity: 0.9,
      width: 480,
      height: 737,
    },
    THIRD: {
      zIndex: 3,
      top: 145,
      width: 515,
      height: 737,
    },
  },
};

const getCardStyle = (isMobile: boolean, isTablet: boolean, isDektop: boolean): IMainCardStyle => {
  let mainStyle = CARDS_STYLE.MOBILE;
  if (isMobile) mainStyle = CARDS_STYLE.MOBILE;
  if (isTablet) mainStyle = CARDS_STYLE.TABLET;
  if (isDektop) mainStyle = CARDS_STYLE.DESKTOP;
  return mainStyle;
};

const getDefaultCards = (
  activeSlide: IStepCard,
  totalSlides: number,
  mainCardStyle: IMainCardStyle
): IStepCard[] => {
  const defCards: IStepCard[] = [];
  Array.from(Array(totalSlides)).forEach((el, index) => {
    if (index < totalSlides - 3) {
      defCards.push({
        cardStyle: mainCardStyle.HIDDEN,
        cardStyleType: ICardStyleType.HIDDEN,
      });
    } else if (index === totalSlides - 3) {
      defCards.push({
        cardStyle: mainCardStyle.FIRST,
        cardStyleType: ICardStyleType.FIRST,
      });
    } else if (index === totalSlides - 2) {
      defCards.push({
        cardStyle: mainCardStyle.SECOND,
        cardStyleType: ICardStyleType.SECOND,
      });
    } else if (index === totalSlides - 1) {
      defCards.push({
        ...activeSlide,
        cardStyle: mainCardStyle.THIRD,
        cardStyleType: ICardStyleType.THIRD,
      });
    }
  });
  return defCards;
};

const goToNextCard = (
  cards: IStepCard[],
  allCards: IStepCard[],
  activeSlide: number,
  mainCardStyle: IMainCardStyle
): IStepCard[] => {
  let allOrganizedCards = cards;

  // region DIFF FOR DINAMIC SLIDES
  const countChangedSlide = allCards.length - (allOrganizedCards.length + activeSlide - 1);
  if (countChangedSlide !== 0) {
    if (countChangedSlide > 0) {
      Array.from(Array(countChangedSlide)).forEach(() => {
        allOrganizedCards.push({
          cardStyle: mainCardStyle.HIDDEN,
          cardStyleType: ICardStyleType.HIDDEN,
        });
      });
    } else {
      allOrganizedCards = allOrganizedCards.slice(0, countChangedSlide);
    }
  }
  // endregion

  // Remove first card
  const filteredSteps = allOrganizedCards.filter((stateStep, indexEl) => {
    if (indexEl !== allOrganizedCards.length - 1) return stateStep;
    return null;
  });

  const returnLastSteps = (indexFiltered: number, filteredStep: IStepCard) => {
    if (indexFiltered === filteredSteps.length - 3) {
      return {
        ...filteredStep,
        cardStyleType: ICardStyleType.FIRST,
        cardStyle: mainCardStyle.FIRST,
      };
    }
    if (indexFiltered === filteredSteps.length - 2) {
      return {
        ...filteredStep,
        cardStyleType: ICardStyleType.SECOND,
        cardStyle: mainCardStyle.SECOND,
      };
    }
    if (indexFiltered === filteredSteps.length - 1) {
      return {
        ...filteredStep,
        component: allCards[activeSlide].component,
        cardStyleType: ICardStyleType.THIRD,
        cardStyle: mainCardStyle.THIRD,
      };
    }
    return filteredStep;
  };

  return filteredSteps.map((filteredStep, indexFiltered) => {
    if (filteredSteps.length > 3) {
      if (indexFiltered < filteredSteps.length - 3) {
        return {
          ...filteredStep,
          cardStyleType: ICardStyleType.HIDDEN,
          cardStyle: mainCardStyle.HIDDEN,
        };
      }
      return returnLastSteps(indexFiltered, filteredStep);
    }
    return returnLastSteps(indexFiltered, filteredStep);
  });
};

const goToPrevCard = (
  cards: IStepCard[],
  allSlide: IStepCard[],
  activeSlide: number,
  mainCardStyle: IMainCardStyle
): IStepCard[] => {
  const filteredCards: IStepCard[] = [];
  cards.reverse().forEach((card) => {
    const { cardStyleType } = card;
    if (cardStyleType === ICardStyleType.THIRD) {
      filteredCards.push({
        ...card,
        component: undefined,
        cardStyleType: ICardStyleType.SECOND,
        cardStyle: mainCardStyle.SECOND,
      });
    } else if (cardStyleType === ICardStyleType.SECOND) {
      filteredCards.push({
        ...card,
        component: undefined,
        cardStyleType: ICardStyleType.FIRST,
        cardStyle: mainCardStyle.FIRST,
      });
    } else if (cardStyleType === ICardStyleType.FIRST) {
      filteredCards.push({
        ...card,
        component: undefined,
        cardStyleType: ICardStyleType.HIDDEN,
        cardStyle: mainCardStyle.HIDDEN,
      });
    } else {
      filteredCards.push({
        ...card,
        component: undefined,
        cardStyleType: ICardStyleType.HIDDEN,
        cardStyle: mainCardStyle.HIDDEN,
      });
    }
  });
  filteredCards.unshift({
    ...allSlide[activeSlide],
    cardStyle: mainCardStyle.THIRD,
    cardStyleType: ICardStyleType.THIRD,
  });
  return filteredCards.reverse();
};

const getResizedSteps = (cards: IStepCard[], mainCardStyle: IMainCardStyle): IStepCard[] =>
  cards.map((card) => {
    switch (card.cardStyleType) {
      case ICardStyleType.HIDDEN:
        return {
          ...card,
          cardStyle: mainCardStyle.HIDDEN,
        };
      case ICardStyleType.THIRD:
        return {
          ...card,
          cardStyle: mainCardStyle.THIRD,
        };
      case ICardStyleType.SECOND:
        return {
          ...card,
          cardStyle: mainCardStyle.SECOND,
        };
      case ICardStyleType.FIRST:
        return {
          ...card,
          cardStyle: mainCardStyle.FIRST,
        };
      default:
        return card;
    }
  });

export const organizeSlide = (
  direction: ICardDirectionType,
  allSteps: IStepCard[],
  stateSteps: IStepCard[],
  currentSlideIndex: number,
  mainCardStyle: IMainCardStyle
): IStepCard[] => {
  if (mainCardStyle) {
    if (currentSlideIndex === 0) {
      return getDefaultCards(allSteps[currentSlideIndex], allSteps.length, mainCardStyle);
    }
    if (currentSlideIndex !== allSteps.length) {
      if (direction === ICardDirectionType.NEXT) {
        return goToNextCard(stateSteps, allSteps, currentSlideIndex, mainCardStyle);
      }
      if (direction === ICardDirectionType.PREV) {
        return goToPrevCard(stateSteps, allSteps, currentSlideIndex, mainCardStyle);
      }
      return [];
    }
    return [];
  }
  return [];
};

export { getCardStyle, getDefaultCards, goToNextCard, goToPrevCard, getResizedSteps };
