import { ICustomSize, ICustomSizeValues } from '@vfit/shared/models';

const DEFAULT_SIZE: ICustomSize = {
  default: {
    width: 404,
  },
  mobile: {
    width: 269,
  },
  tablet: {
    width: 328,
  },
};

/**
 * Return the size of slide
 * @param isMobile
 * @param isTablet
 */
const getSlideSize = (isMobile: boolean, isTablet: boolean): ICustomSizeValues => {
  if (isMobile) return DEFAULT_SIZE.mobile;
  if (isTablet) return DEFAULT_SIZE.tablet || DEFAULT_SIZE.default;
  return DEFAULT_SIZE.default;
};

const updateAllHeight = (ref: HTMLDivElement) => {
  const pagination = ref.getElementsByClassName('swiper-pagination');
  (pagination[0] as HTMLElement).style.marginBottom = '42px';
  const allSlides = ref.getElementsByClassName('swiper-slide');
  const allHeight = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < allSlides.length; i++) {
    const allCardsHeight = allSlides[i].getElementsByClassName('bottom-container-card-info');
    if (allCardsHeight?.[0]) {
      const icon = 50;
      const marginTop = 20;
      const title = allCardsHeight?.[0]?.getElementsByClassName('card-info-title')?.[0];
      const description = allCardsHeight?.[0]?.getElementsByTagName('div')?.[0];
      const button = allCardsHeight?.[0]?.getElementsByTagName('button')?.[0];
      const height = icon + (title?.clientHeight || 0) + (description?.clientHeight || 0) + (button?.clientHeight + marginTop || 0);
      if (height) {
        allHeight.push(height);
      }
    }
  }
  const maxH = Math.max(...allHeight);
  if (maxH) {
    for (let i = 0; i < allSlides.length; i += 1) {
      const allCardsHeight = allSlides[i].getElementsByClassName('bottom-container-card-info');
      if (allCardsHeight?.[0]) {
        (allCardsHeight[0] as HTMLDivElement).style.height = `${maxH}px`;
      }
    }
  }
};

export { getSlideSize, DEFAULT_SIZE, updateAllHeight };
