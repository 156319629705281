import { ICustomSize } from '@vfit/shared/models';

const DEFAULT_SIZE: ICustomSize = {
  default: {
    width: 405,
    height: 600,
  },
  mobile: {
    width: 327,
    height: 480,
  },
  large: {
    width: 625,
    height: 480,
  },
  large_mobile: {
    width: 450,
    height: 480,
  },
  masked: {
    width: 296,
    height: 480,
  },
  maskedMobile: {
    width: 269,
    height: 350,
  },
};

export { DEFAULT_SIZE };
