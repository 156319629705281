import { Children } from 'react';
import { Pill } from '@vfit/shared/atoms';
import { colors } from '@vfit/shared/themes';
import { PillsContainer } from './pills.style';

export const Pills = ({ pills, variant = colors.$ffffff, opacity = 0.8, borderColor }: { pills: string[]; variant?: string, opacity?: number, borderColor?: string }) => (
  <PillsContainer>
    {Children.toArray(
      pills
        ?.filter(Boolean)
        ?.slice(0, 3)
        ?.map((pill) => (
          <div>
            <Pill label={pill} pillsColor={variant} opacity={opacity} borderColor={borderColor} />
          </div>
        ))
    )}
  </PillsContainer>
);

export default Pills;
