import { Children } from 'react';
import { ImageDivider, CustomText, ImageAtoms, Button } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { getVariantByActionStyle } from '@vfit/shared/data-access';
import { ISplittedCard, ISplittedImageTextCardList } from './splittedImageTextCardList.models';
import {
  MainContainer,
  TitleContainer,
  DescriptionContainer,
  Info,
  Container,
  CardContainer,
  DescriptionBox,
  Title,
  Description,
  CtaContainer,
} from './splittedImageTextCardList.style';

const SplittedImageTextCardList = ({
  divider,
  description,
  topText,
  disclaimer,
  elements,
  handleButtonClick,
}: ISplittedImageTextCardList) => {
  const { isMobile } = useDeviceType();

  return (
    <>
      {topText && (
        <TitleContainer>
          <CustomText text={topText} />
        </TitleContainer>
      )}
      {description && (
        <DescriptionContainer>
          <CustomText text={description} />
        </DescriptionContainer>
      )}
      <Container>
        <MainContainer>
          {Children.toArray(
            elements.map(
              ({
                title,
                description: elementDescription,
                action,
                urlImg,
                urlImgMobile,
                isLastCard,
                actionNavigationUrl,
              }: ISplittedCard) => (
                <CardContainer isLastCard={isLastCard}>
                  <DescriptionBox>
                    {title && <Title>{title}</Title>}
                    {elementDescription && (
                      <Description>
                        <CustomText text={elementDescription} />
                      </Description>
                    )}
                    {action?.title && (
                      <CtaContainer>
                        <Button
                          onClick={() =>
                            // actionClick is inhibited to avoid double url opening
                            !actionNavigationUrl && handleButtonClick && handleButtonClick?.(action)
                          }
                          href={actionNavigationUrl}
                          target={
                            actionNavigationUrl && action?.isBlank === 'true' ? '_blank' : undefined
                          }
                          name={`action_SplittedImageTextCardList_${action?.type}`}
                          variant={getVariantByActionStyle(action.style)}
                        >
                          {action.title}
                        </Button>
                      </CtaContainer>
                    )}
                  </DescriptionBox>
                  {!isMobile && (
                    <ImageAtoms
                      nameCard="imgCtaContainer"
                      alt="cta"
                      image={urlImg}
                      imageMobile={urlImgMobile}
                      isMobile={isMobile}
                    />
                  )}
                </CardContainer>
              )
            )
          )}
          <Info>{disclaimer && <CustomText text={disclaimer} />}</Info>
        </MainContainer>
        {divider?.image && (
          <ImageDivider
            image={divider.image}
            imageMobile={divider.imageMobile}
            isMobile={isMobile}
            alt={divider.text || ''}
          />
        )}
      </Container>
    </>
  );
};

export default SplittedImageTextCardList;

