import React, { MouseEvent, useEffect, useRef } from 'react';
import { useSwiper, useSwiperSlide } from 'swiper/react';
import { useDeviceType } from '@vfit/shared/hooks';
import { requireVideo } from '@vfit/shared/data-access';
import { ButtonSlide, ImageAtoms } from '@vfit/shared/atoms';
import { IGradientImage, ISlideCarouelProps } from '@vfit/shared/models';
import {
  SlideCarouselContainerImage,
  SlideCarouselContainerVideo,
  SlideCarouselNoMaskTop,
  SlideCarouselNoMaskBottomButton,
  SlideCarouselNoMaskTopChild,
  SlideCarouselNoMaskBottomChild,
  SlideCarouselNoMaskBottomChildText,
  SlideCarouselNoMaskBottom,
  SlideCarouselMaskBottom,
  SlideCarouselMaskTop,
  SlideCarouselMaskBottomChild,
  SlideCarouselMaskBottomChildText,
  SlideCarouselMaskBottomChildIcon,
  SlideCarouselOverlayImage,
  DivImgContainer,
} from './slideCarousel.style';
import { DEFAULT_SIZE } from './slideCarousel.utils';

const SlideCarousel = ({
  slide,
  position,
  width,
  height,
  textBottom,
  coveredText = false,
  zoomIn = true,
  defaultSize = DEFAULT_SIZE.default,
  forcePlayVideo,
  isEnabledOnClickAction,
  handleClickButton,
}: ISlideCarouelProps) => {
  const {
    id,
    category,
    title,
    overlayImage,
    overlayImageMobile,
    description,
    action,
    withMask,
    maskIcon,
    maskIconSize,
  } = slide;
  const { isMobile } = useDeviceType();
  const sliderCarouel = useSwiper();
  const swiperSlide = useSwiperSlide();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const showButton = action?.title || action?.small;

  useEffect(() => {
    if (isMobile && slide.video) {
      const { current } = videoRef;
      if (forcePlayVideo) {
        current?.play();
      } else if (swiperSlide?.isActive && current) {
        current?.play();
      } else if (!swiperSlide?.isActive && current && !isEnabledOnClickAction) {
        current?.pause();
      } else {
        current?.play();
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [slide, isMobile, videoRef, swiperSlide?.isActive]);

  const handleVideoEnter = (event: MouseEvent<HTMLDivElement>) => {
    const { current } = videoRef;
    if (isMobile) return;
    if (!event) return;
    if (current) current?.play();
  };

  const handleVideoExit = (event: MouseEvent<HTMLDivElement>) => {
    const { current } = videoRef;
    if (isMobile) return;
    if (!event) return;
    if (current) current?.pause();
  };

  const handleClickCard = () => {
    if (isMobile && !isEnabledOnClickAction) {
      if (sliderCarouel) {
        sliderCarouel.slideNext();
      }
    } else if (handleClickButton) {
      handleClickButton();
    }
  };

  // this function render the standard EMOTIONAL CARD present on figma

  const withoutMaskCard = (): React.ReactNode => (
    <>
      <SlideCarouselNoMaskTop>
        {!textBottom && (
          <SlideCarouselNoMaskTopChild>
            {category && <h2>{category}</h2>}
            {title && <h3>{title}</h3>}
            {description && <p>{description}</p>}
          </SlideCarouselNoMaskTopChild>
        )}
      </SlideCarouselNoMaskTop>
      <SlideCarouselNoMaskBottom>
        {(overlayImage || overlayImageMobile) && (
          <SlideCarouselOverlayImage>
            <div
              style={{
                height: 148,
              }}
            >
              <ImageAtoms
                nameCard="overlay"
                image={overlayImage}
                imageMobile={overlayImageMobile}
                isMobile={isMobile}
                key={overlayImage || overlayImageMobile}
              />
            </div>
          </SlideCarouselOverlayImage>
        )}
        {textBottom && (
          <SlideCarouselNoMaskBottomChildText
            coveredText={coveredText}
            paddingBottom={!description}
          >
            {category && <h2>{category}</h2>}
            {title && <h3 className="title-emotionalCard">{title}</h3>}
            {description && <p>{description}</p>}
          </SlideCarouselNoMaskBottomChildText>
        )}
        <SlideCarouselNoMaskBottomChild>
          {showButton && (
            <SlideCarouselNoMaskBottomButton isSmall={action?.small || false}>
              <ButtonSlide
                small={action?.small || false}
                label={action?.title || 'Show offer'}
                smallSize={55}
                buttonColor="#fff"
                labelColor="#262626"
                hoverColor={action?.small ? '#F2F2F2' : 'rgba(255, 255, 255, 0.8)'}
                clickColor={action?.small ? '#F2F2F2' : 'rgba(255, 255, 255, 0.6)'}
                onClick={(e) => {
                  e.stopPropagation();
                  if (handleClickButton) {
                    handleClickButton();
                  }
                }}
                name={`action_slideCarousel_${id ? id.toString() : action?.type?.toString() || ''}`}
              />
            </SlideCarouselNoMaskBottomButton>
          )}
        </SlideCarouselNoMaskBottomChild>
      </SlideCarouselNoMaskBottom>
    </>
  );

  // this function render the mask part of the card with only title and description

  const withMaskCard = (): React.ReactNode => (
    <>
      <SlideCarouselMaskTop />
      <SlideCarouselMaskBottom>
        <SlideCarouselMaskBottomChild>
          <SlideCarouselMaskBottomChildText withImage={!!maskIcon}>
            {title && <h3>{title}</h3>}
            {description && <p>{description}</p>}
          </SlideCarouselMaskBottomChildText>
          <SlideCarouselMaskBottomChildIcon>
            {maskIcon && (
              <div
                style={{
                  width: maskIconSize || 50,
                }}
              >
                <ImageAtoms alt="icon" nameCard="mask" image={maskIcon} />
              </div>
            )}
          </SlideCarouselMaskBottomChildIcon>
        </SlideCarouselMaskBottomChild>
      </SlideCarouselMaskBottom>
    </>
  );

  const findGradient = textBottom
    ? IGradientImage.GRADIENT_BOTTOM_70
    : IGradientImage.GRADIENT_TOP_35;

  return (
    <div key={`${Math.random()}`} role="button" tabIndex={0} onKeyDown={handleClickCard} onClick={handleClickCard}>
      {!slide.video && (
        <SlideCarouselContainerImage
          width={width || defaultSize.width}
          height={height || defaultSize.height}
        >
          <DivImgContainer absolute zoomIn={zoomIn}>
            <ImageAtoms
              gradient={!withMask ? findGradient : undefined}
              nameCard="slideCard"
              image={slide.image}
              imageMobile={slide.imageMobile}
              isMobile={isMobile}
            />
          </DivImgContainer>
        </SlideCarouselContainerImage>
      )}
      {slide.video && (
        <SlideCarouselContainerVideo
          width={width || defaultSize.width}
          height={height || defaultSize.height}
        />
      )}
      {slide.video && (
        <SlideCarouselContainerVideo
          width={width || defaultSize.width}
          height={height || defaultSize.height}
        >
          <div onMouseEnter={handleVideoEnter} onMouseLeave={handleVideoExit}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              autoPlay
              playsInline
              ref={videoRef}
              src={requireVideo(slide.video, slide.videoMobile || '', isMobile)}
              loop
              muted
            />
          </div>
        </SlideCarouselContainerVideo>
      )}
      {!withMask && withoutMaskCard()}
      {withMask && withMaskCard()}
    </div>
  );
};

export default SlideCarousel;
