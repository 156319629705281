import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div<{ isApp?: boolean; iconSize?: number }>`
  margin-top: 50px;
  padding: 40px 24px 32px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.$bebebe};
  border-radius: ${({ isApp }) => `${isApp ? '6px' : '20px'}`};
  background-color: ${colors.$ffffff};
  min-height: 333px;

  .bottom-container-card-info {
    position: relative;
    min-height: 333px;
  }

  .wrapperIcon {
    width: ${(props) => `${props.iconSize ? props.iconSize : 50}px`};
    height: ${(props) => `${props.iconSize ? props.iconSize : 50}px`};
  }

  svg {
    width: 100%;
  }

  .numberBox {
    margin: 0;
    font-family: ${fonts.light};
    font-weight: 400;
    ${pxToCssFont(42, 38)}
    color: ${colors.$e60000};

    @media (min-width: ${breakpoints.tablet}) {
      ${pxToCssFont(42, 52)}
    }

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(70, 70)}
    }
  }
`;

export const Title = styled.p<{ type: string }>`
  margin: 0;
  margin-top: 24px;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(24, 30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
    margin-top: ${(props) => `${props.type === 'number' ? 16 : 24}px`};
  }
`;

export const TextContainer = styled.div`
  div {
    margin: 0;
    margin-top: 24px;
    ${pxToCssFont(18, 22)}
    color: ${colors.$262626};
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin: 0;
      margin-top: 24px;
      ${pxToCssFont(20, 30)}
    }
  }
`;

export const FrontLabel = styled.div`
  font-family: ${fonts.regular};
  ${pxToCssFont(18, 24)}
  color: ${colors.$262626};
`;

export const ButtonContainer = styled.div`
  .capitalize-first {
    text-transform: lowercase;
  }
  .capitalize-first::first-letter {
    text-transform: uppercase;
  }
  text-align: center;
  margin-top: 20px;
  width: 100%;
  button,
  a {
    margin: 0 auto;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
    }
  }
  white-space: nowrap;
`;

