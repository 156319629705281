import { Children, useEffect, useState } from 'react';
import { ImageAtoms } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, FreeMode, Mousewheel, Keyboard, EffectCards } from 'swiper';
import { Arrow, ZoomPlus } from '@vfit/shared-icons';
import { IGalleryImage } from './galleryImage.models';
import {
  GalleryImageContainer,
  SelectedImageContainer,
  ThumbnailContainer,
  IconContainer,
  ArrowButton,
  BoxImage,
} from './galleryImage.style';
import VfModal from '../VfModal/vfModal';
import ThumbnailSwiper from '../ThumbnailSwiper/thumbnailSwiper';

const GalleryImage = ({ images, externalUrl, slideStyle }: IGalleryImage) => {
  const { isMobile } = useDeviceType();
  const [imageSelected, setImageSelected] = useState<string | null>();
  const [zoomOpen, setZoomOpen] = useState<boolean>(false);

  useEffect(() => {
    if (images) {
      setImageSelected(images[0]);
    }
  }, [images]);

  const swiperProduct = () => (
    <Swiper
      slidesPerView="auto"
      effect="slide"
      cardsEffect={{
        rotate: true,
        slideShadows: false,
      }}
      mousewheel={{
        forceToAxis: true,
      }}
      spaceBetween={33}
      freeMode={false}
      centeredSlides={isMobile}
      keyboard
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, FreeMode, Mousewheel, Keyboard, EffectCards]}
    >
      {Children.toArray(
        Children.toArray(
          images?.map((img, index) => (
            <SwiperSlide
              style={
                slideStyle || {
                  borderRadius: '0px',
                  height: 480,
                  width: 375,
                }
              }
            >
              <ImageAtoms
                nameCard={`device_slide${index}`}
                image={img}
                external={externalUrl}
                type={externalUrl ? 'default' : 'lambda'}
              />
            </SwiperSlide>
          ))
        )
      )}
      <IconContainer onClick={() => setZoomOpen(true)}>
        <ZoomPlus />
      </IconContainer>
    </Swiper>
  );

  const handleClickDirectionImage = (direction: string) => {
    switch (direction) {
      case 'down':
        if (imageSelected === images?.[images.length - 1]) {
          setImageSelected(images?.[0]);
        } else {
          setImageSelected(images?.[images.indexOf(imageSelected!) + 1]);
        }
        break;
      default:
        if (imageSelected === images?.[0]) {
          setImageSelected(images?.[images.length - 1]);
        } else {
          setImageSelected(images?.[images.indexOf(imageSelected!) - 1]);
        }
    }
  };

  return (
    <GalleryImageContainer>
      {!isMobile && (
        <>
          <ThumbnailContainer>
            <ArrowButton
              rotation={-90}
              color="#BEBEBE"
              arrowWidth={10}
              arrowHeigth={10}
              onClick={() => handleClickDirectionImage('up')}
            >
              <Arrow />
            </ArrowButton>
            {Children.toArray(
              images?.map(
                (img, index) =>
                  img && (
                    <div>
                      <BoxImage
                        selected={img === imageSelected}
                        onClick={() => setImageSelected(img)}
                      >
                        <ImageAtoms
                          nameCard={`gallery_device_${index}`}
                          image={img}
                          external={externalUrl}
                          type={externalUrl ? 'default' : 'lambda'}
                          key={img}
                        />
                      </BoxImage>
                    </div>
                  )
              )
            )}
            <ArrowButton
              rotation={90}
              color="#BEBEBE"
              arrowWidth={10}
              arrowHeigth={10}
              onClick={() => handleClickDirectionImage('down')}
            >
              <Arrow />
            </ArrowButton>
          </ThumbnailContainer>
          <SelectedImageContainer>
            <ImageAtoms
              key={imageSelected}
              nameCard="gallery_device_selected"
              image={imageSelected || images?.[0] || ''}
              external={externalUrl}
              type={externalUrl ? 'default' : 'lambda'}
            />
          </SelectedImageContainer>
        </>
      )}
      {isMobile && swiperProduct()}
      <VfModal isOpen={zoomOpen} handleClose={() => setZoomOpen(false)}>
        <ThumbnailSwiper images={images} externalUrl={externalUrl} />
      </VfModal>
    </GalleryImageContainer>
  );
};

export default GalleryImage;
