import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const CardInfoDevicWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`;

export const Container = styled.div<{ enableAutoHeight?: boolean; disableMarginTop?: boolean }>`
  width: 100%;
  margin-top: ${({ disableMarginTop }) => (disableMarginTop ? 0 : '36px')};
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid ${colors.$bebebe};
  border-radius: 20px;
  background-color: ${colors.$ffffff};
  min-height: ${({ enableAutoHeight }) => (enableAutoHeight ? 'auto' : 493)};
  position: relative;

  @media (min-width: ${breakpoints.tablet}) {
    width: 296px;
  }

  .button {
    justify-self: flex-end;
    padding: 0 24px;
    margin: auto 0 32px;
  }
`;

export const ContainerInfo = styled.div<{disabled?:boolean}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
  opacity:${({disabled})=>(disabled ? 0.5 :1)};
  div {
    margin: 0;
  }

  h4 {
    font-family: ${fonts.regular};
    ${pxToCssFont(14, 18)}
    font-weight: 700;
    color: ${colors.$7e7e7e};
    margin: 0;
    max-width: none;
  }

  .description {
    div {
      margin: 0;

      p {
        color: ${colors.$7e7e7e};
        margin: 0;
        font-weight: 700;
      }
    }
  }
`;

export const Price = styled.div`
  ${pxToCssFont(12, 16)}
  display: flex;
  align-items: center;

  .discount div div {
    padding: 6px 8px;
  }
`;

export const ProductCardOfferLabel = styled.div<{ haveLabel: boolean }>`
  width: 100%;
  background-color: ${({ haveLabel }) => (haveLabel ? '#00697C' : 'transparent')};
  border-radius: 20px;
  height: 73px;
  position: absolute;

  span {
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 700;
    ${pxToCssFont(12, 16)}
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${colors.$ffffff};
    padding-top: 10px;
    margin-left: 36px;
    display: flex;
  }
`;

export const ImageDevices = styled.div`
  text-align: center;
  height: 200px;

  img {
    width: auto;
    height: 100%;
  }
`;
