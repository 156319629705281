import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const PillContainer = styled.div<{
  pillsColor?: string;
  opacity?: number;
  borderColor?: string;
}>`
  ${({ pillsColor }) => `background: ${pillsColor || colors.$ffffff}`};
  ${({ opacity }) => `opacity: ${opacity}`};
  border-radius: 20px;
  padding: 4px 10px;
  ${({ borderColor }) => `border: ${borderColor ? `1px solid ${borderColor}` : 'none'}`};

  span {
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(12, 16)}
    text-transform: uppercase;
    color: ${colors.$0d0d0d};
    letter-spacing: 0.5px;
    white-space: nowrap;
    ${({ pillsColor }) =>
      `color: ${pillsColor === colors.$262626 ? colors.$ffffff : colors.$262626}`};
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 6px 12px;
  }
`;
