import {
  Wrapper,
  Suggestions,
  SuggestionItem,
  FillBorder,
  SuggestionItemPlaceholder,
} from './popover.style';
import { IPopoverProps } from './popover.models';

/** POPOVER DOCS
 * @description This component aims to display an array of google.maps.places.AutocompletePrediction strings passed from "props.items" as a list below the
 * "props.target" ReactNode componet.
 * @param props -
 * - items : google.maps.places.AutocompletePrediction[] --> (REQUIRED) array of suggestion to be displayed below target component
 * - target : ReactNode --> (REQUIRED) item to be wrapped by Popover
 * - isDisable : boolean --> (REQUIRED) to handle popover view
 * - handlePlaceSelect : (google.maps.places.AutocompletePrediction) => void --> (REQUIRED) to handle suggestion select
 * @author Andrea
 */

const Popover = (props: IPopoverProps) => {
  const { target, items, isDisabled, placeholder, handlePlaceSelect } = props;
  return (
    <div>
      {target}
      {items && !isDisabled && (
        <>
          <FillBorder />
          <Wrapper>
            <Suggestions>
              {items.slice(0, 4).map((el) => (
                <SuggestionItem
                  key={el.place_id}
                  onClick={() => {
                    handlePlaceSelect(el);
                  }}
                >
                  {el.description}
                </SuggestionItem>
              ))}
              {placeholder?.enabled && placeholder.label && (
                <SuggestionItemPlaceholder onClick={placeholder.action}>
                  {placeholder.label}
                </SuggestionItemPlaceholder>
              )}
            </Suggestions>
          </Wrapper>
        </>
      )}
    </div>
  );
};

export default Popover;
