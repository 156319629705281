import { ForwardedRef, forwardRef } from 'react';
import { ErrorIcon } from '@vfit/shared-icons';
import { ITextAreaInputProps } from './textAreaInput.model';
import { Fieldset, Legend, Field, ErrorMessage } from './textAreaInput.style';

/** TEXT AREA INPUT DOCS
 * @description This component aims to display a customized textarea component.
 * @param props -
 * - label : string --> (REQUIRED) legend on top of Input
 * - disabled : boolean --> (DEFAULT FALSE) to manage disabled input
 * - rest... --> remaining props from Input Text default component
 * @author Andrea
 */
const TextAreaInput = forwardRef<HTMLInputElement, ITextAreaInputProps>(
  (props: ITextAreaInputProps, ref: ForwardedRef<any>) => {
    const {
      label,
      icon,
      onIconClick,
      error,
      disabled = false,
      hidden,
      isErrorMessageDisabled,
      isErrorIconDisabled,
      ...rest
    } = props;

    return (
      <>
        {!hidden && (
          <div>
            <Fieldset error={error} isDisabled={disabled} className="textAreaInputFieldset">
              <div className="wrapper">
                <Field
                  disabled={disabled}
                  isDisabled={disabled}
                  value={props.value}
                  ref={ref}
                  {...rest}
                />
                <Legend error={error}>{label}</Legend>
                {(icon || error) && (
                  <span className="wrapper-icon">
                    <button type="button" className="icon" onClick={onIconClick}>
                      {error && !isErrorIconDisabled ? <ErrorIcon /> : icon}
                    </button>
                  </span>
                )}
              </div>
            </Fieldset>
          </div>
        )}
        {!isErrorMessageDisabled && error && error !== ' ' && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
  }
);

export default TextAreaInput;

