import { FixedSizeList } from 'react-window';
import { useDeviceType } from '@vfit/shared/hooks';
import { SuggestionItem } from './flatList.style';

const getItemKey = (index: number, data: any) => data[index];

const Flatlist: React.FC<{
  onSelect: (element: string) => void;
  items: string[];
  itemCount: number;
}> = ({ onSelect, items, itemCount }) => {
  const { isMobile } = useDeviceType();
  return (
    <FixedSizeList
      height={itemCount > 3 ? 120 : itemCount * 40}
      itemSize={40}
      itemCount={itemCount}
      width="auto"
      itemData={items}
      itemKey={getItemKey}
    >
      {({ index, data: itemsData, style }) => (
        <SuggestionItem
          style={style}
          onClick={(event) => {
            if (!isMobile) event.preventDefault();
            else onSelect(itemsData[index]);
          }}
          onPointerDown={(event) => {
            if (isMobile) event.preventDefault();
            else onSelect(itemsData[index]);
          }}
        >
          {itemsData[index]}
        </SuggestionItem>
      )}
    </FixedSizeList>
  );
};

export default Flatlist;
