import styled from 'styled-components';
import { breakpoints, colors, fonts } from '@vfit/shared/themes';

export const ConnectionCardsContainer = styled.div`
  display: block;

  .swiper-wrapper {
    height: inherit;
  }

  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 45px;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 120px;
  }
`;

export const ConnectionCardsProduct = styled.div`
  margin-right: 26px;

  @media (min-width: ${breakpoints.desktop}) {
    margin-right: 32px;
  }
`;

export const ConnectionCardsText = styled.div`
  div {
    text-align: center;
  }

  @media (min-width: ${breakpoints.tablet}) {
    div {
      text-align: center;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin-left: 80px;
      text-align: left;
    }
  }
`;

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }
`;

