import { PropsWithChildren } from 'react';
import { Pills } from '@vfit/shared/components';
import { colors, fonts } from '@vfit/shared/themes';
import { CustomText, ImageAtoms, Button } from '@vfit/shared/atoms';
import { getVariantByActionStyle } from '@vfit/shared/data-access';
import {
  Container,
  ContainerInfo,
  ImageDevices,
  Price,
  ProductCardOfferLabel,
  CardInfoDevicWrapper,
} from './cardInfoDevice.style';
import { ICardInfoDevice } from './cardInfoDevice.model';

const CardInfoDevice = ({
  image,
  brand,
  title,
  offerLabel,
  price,
  description,
  discount,
  action,
  haveMarketingLabel,
  tagLine,
  externalUrl,
  actionClick,
  isOutOfStock,
  outOfStockLabel,
  enableAutoHeight,
  disableMarginTop,
  customTracking,
  disableDeviceInfo,
  actionNavigationUrl,
  children,
}: PropsWithChildren<ICardInfoDevice>) => (
  <CardInfoDevicWrapper>
    {haveMarketingLabel && (
      <ProductCardOfferLabel haveLabel={!!tagLine}>
        {tagLine && <span>{tagLine}</span>}
      </ProductCardOfferLabel>
    )}
    <Container
      className="card-info-container"
      enableAutoHeight={enableAutoHeight}
      disableMarginTop={disableMarginTop}
    >
      <ImageDevices>
        <ImageAtoms
          key={image}
          nameCard="imageDevices"
          image={image}
          external={externalUrl}
          type={externalUrl ? 'default' : 'lambda'}
          style={{ opacity: isOutOfStock ? '50%' : '100%' }}
        />
      </ImageDevices>
      <ContainerInfo disabled={disableDeviceInfo}>
        <div >
          <CustomText
            text={brand || ''}
            textAlign="left"
            size={18}
            lineHeight={24}
            fontFamily={fonts.regular}
          />
          <CustomText
            text={title || ''}
            textAlign="left"
            size={28}
            lineHeight={36}
            fontFamily={fonts.exbold}
          />
        </div>
        <div>
          {offerLabel && (
            <CustomText
              text={offerLabel || ''}
              textAlign="left"
              size={16}
              lineHeight={22}
              fontFamily={fonts.regular}
            />
          )}
          {price && (
            <Price>
              <CustomText text={price || ''} textAlign="left" size={28} lineHeight={36} />
              <div className="discount">
                <Pills pills={[discount || '']} variant={colors.$00697c} />
              </div>
            </Price>
          )}
          <div className="description">
            <CustomText
              text={description || ''}
              textAlign="left"
              size={14}
              lineHeight={18}
              fontFamily={fonts.regular}
            />
          </div>
          {children}
        </div>
      </ContainerInfo>
      {action && (
        <div className="button">
          <Button
            onClick={() => {
              customTracking?.();
              // when passing href actionClick must be inhibited to avoid double url opening
              actionClick?.(action);
            }}
            href={actionNavigationUrl}
            target={actionNavigationUrl && action?.isBlank === 'true' ? '_blank' : undefined}
            name={`action_cardInfoDevice_${action?.type?.toString() || ''}`}
            variant={getVariantByActionStyle(action?.style)}
            disabled={isOutOfStock}
          >
            {isOutOfStock ? outOfStockLabel : action?.title}
          </Button>
        </div>
      )}
    </Container>
  </CardInfoDevicWrapper>
);

export default CardInfoDevice;

