import { callAutocomplete } from '@vfit/shared/hooks';
import { debounce } from 'lodash-es';
import { ChangeEventHandler, forwardRef, useCallback, useState } from 'react';
import { ExitIcon, LocateMeSystem } from '@vfit/shared-icons';
import { LoggerInstance } from '@vfit/shared/data-access';
import { LoadingSpinner } from '@vfit/shared/atoms';
import { IAddressInputProps } from './addressInput.models';
import Popover from '../../../../../../shared/atoms/src/lib/Popover/popover';
import TextInput from '../TextInput/textInput';
import { LoadingContent } from './addressInput.style';

/** ADDRESS INPUT DOCS
 * @description It takes a string and it rendes some suggestions coming from google maps API.
 * Value of the form input is a string of the placeId result.
 * @param props -
 *  - onChange : (string | undefined) => void --> to handle token from address recovery
 *  - onIconClick : () => void --> to handle click over the icon map
 * @author Andrea
 */
const AddressInput = forwardRef<HTMLInputElement, IAddressInputProps>((props, ref) => {
  const { onChange, onIconClick, placeholderAutocomplete, errorMessage, ...rest } = props;
  const [value, setValue] = useState(props.defaultValue && props.defaultValue !== '' ? props.defaultValue : '');
  const [isHidden, setIsHidden] = useState(true);
  const [isError, setIsError] = useState('');
  const [isErrorIconDisabled, setIsErrorIconDisabled] = useState(true);
  const [isCancellable, setIsCancellable] = useState(props.defaultValue && props.defaultValue !== '');
  const [isLoadingGeolocation, setIsLoadingGeolocation] = useState(false);
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);

  const getSuggestions = debounce(
    async (input: string) => {
      const data = await callAutocomplete(input);
      // TODO --> fast keystroke then fast deleting --> remaining suggestions
      if (data.length === 0) {
        setSuggestions([]);
        setIsHidden(true);
      } else {
        setSuggestions(data);
        setIsHidden(false);
      }
    },
    500,
    { leading: true, trailing: true }
  );

  /* useEffect(() => {
    if (props.defaultValue && props.defaultValue !== '') {
      setValue(props.defaultValue);
      props?.changeValue?.(props.defaultValue);
      setIsCancellable(true);
    }
  }, [props.defaultValue]); */

  /** HANDLE VALUE CHANGE
   * @description If ther is no target.value it resets Value, Suggestions and it toggles
   * the popover suggestions'visibility.
   * If target.value exists it sets the value and it calls getPredictions().
   * @param event from input text onChange event handler
   * @author Andrea
   */
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (!event.target.value.trim()) {
        setValue('');
        props?.changeValue?.('');
        setIsError('Campo obbligatorio');
        setIsCancellable(false);
        setIsErrorIconDisabled(false);
        setSuggestions([]);
        setIsHidden(true);
        getSuggestions.cancel();
      } else {
        setValue(event.target.value);
        props?.changeValue?.(event.target.value);
        setIsErrorIconDisabled(false);
        setIsError('');
        setIsCancellable(true);
        getSuggestions(event.target.value);
      }
      if (onChange) onChange('');
    },
    [getSuggestions, onChange, setIsHidden]
  );

  const handlePlaceSelect = useCallback(
    (selectedValue: google.maps.places.AutocompletePrediction) => {
      setValue(selectedValue.description);
      props?.changeValue?.(selectedValue.description);
      setIsError('');
      setIsErrorIconDisabled(false);
      setIsHidden(true);
      if (onChange) onChange(selectedValue.place_id);
    },
    [onChange]
  );

  function handleGeocoding(p: GeolocationPosition) {
    if (!props.findPosition) return;
    props
      .findPosition(p)
      .then((res: any) => {
        setIsLoadingGeolocation(false);
        if (props.onChangeGeolocation) props.onChangeGeolocation(false);
        setValue(`${res.street !== '' ? `${res.street}, ` : ''}${res.city}, ${res.province}`);
        props?.changeValue?.(`${res.street !== '' ? `${res.street}, ` : ''}${res.city}, ${res.province}`);
        setIsErrorIconDisabled(false);
        setIsError('');
        setIsCancellable(true);
      })
      .catch(() => {
        setIsLoadingGeolocation(false);
        if (props.onChangeGeolocation) props.onChangeGeolocation(false);
      });
  }

  const onIconClickHandler = () => {
    const geolocationNotActive = () => {
      // TODO handle error
      LoggerInstance.debug(errorMessage || '');
    };

    if (isCancellable) {
      if (onIconClick) onIconClick();
      setValue('');
      props?.changeValue?.('');
      setIsHidden(true);
      setIsCancellable(false);
      setIsErrorIconDisabled(false);
      setIsError('Campo obbligatorio');
      document.getElementById('addressAutocomplete')?.focus();
    } else if (isErrorIconDisabled) {
      if (navigator.geolocation) {
        setIsLoadingGeolocation(true);
        if (props.onChangeGeolocation) props.onChangeGeolocation(true);
        setTimeout(() => {
          // navigator.permissions.query({name: 'geolocation'}).then(() => {
          navigator.geolocation.getCurrentPosition(
            handleGeocoding,
            () => {
              setIsLoadingGeolocation(false);
              geolocationNotActive();
              LoggerInstance.debug('Geolocation is not supported by this browser.');
              if (props.onChangeGeolocation) props.onChangeGeolocation(false);
            },
            {
              enableHighAccuracy: true,
              maximumAge: 6000,
              timeout: 10000,
            }
          );
        }, 500);
      } else {
        setIsLoadingGeolocation(false);
        geolocationNotActive();
        LoggerInstance.debug('Geolocation is not supported by this browser.');
      }
    } else {
      LoggerInstance.debug('Icon not active.');
    }
  };

  const getIcon = () => {
    if (isCancellable) return <ExitIcon />;
    if (!props.findPosition) return undefined;
    return isLoadingGeolocation ? (
      <LoadingContent>
        <LoadingSpinner />
      </LoadingContent>
    ) : (
      <LocateMeSystem />
    );
  };

  return (
    <Popover
      handlePlaceSelect={handlePlaceSelect}
      isDisabled={isHidden}
      items={suggestions}
      placeholder={placeholderAutocomplete}
      target={
        <TextInput
          onIconClick={onIconClickHandler}
          icon={getIcon()}
          ref={ref}
          placeholder=" "
          id="addressAutocomplete"
          value={value}
          onChange={handleChange}
          error={isError}
          isErrorIconDisabled={isErrorIconDisabled}
          onFocus={(event) => {
            setIsErrorIconDisabled(true);
            if (rest.onFocus) rest.onFocus(event);
          }}
          onBlurCapture={() =>
            setTimeout(() => {
              setIsErrorIconDisabled(false);
            }, 800)
          }
          {...rest}
        />
      }
    />
  );
});

export default AddressInput;
