import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { ErrorIcon } from '@vfit/shared-icons';
import InputMask from 'react-input-mask';
import { IMaskInputProps } from './maskInput.models';
import { FieldsetWrap, Fieldset, Legend, Field, ErrorMessage } from './maskInput.style';

const MaskInput = forwardRef<HTMLInputElement, IMaskInputProps>((props, ref: ForwardedRef<any>) => {
  const {
    label,
    icon,
    mask,
    onIconClick,
    error,
    disabled = false,
    hidden,
    isErrorMessageDisabled,
    isErrorIconDisabled,
    onBlur,
    ...rest
  } = props;

  const [raiseLabel, setRaiseLabel] = useState<boolean>(false);
  const value = (document.getElementById('inputMaskId') as HTMLInputElement)?.value;

  useEffect(() => {
    if (value) {
      setRaiseLabel(true);
    }
  }, [value]);

  return (
    <>
      {!hidden && (
        <FieldsetWrap>
          <Fieldset error={error} isDisabled={disabled} className="maskInputFieldset">
            <div className="wrapper">
              <Field style={{ width: '100%' }}>
                <InputMask
                  {...rest}
                  id="inputMaskId"
                  mask={mask}
                  maskChar={null}
                  ref={ref}
                  disabled={disabled}
                  alwaysShowMask={false}
                  onFocus={() => setRaiseLabel(true)}
                  onBlur={(e) => {
                    if (e.target.value === '') {
                      setRaiseLabel(false);
                    }
                    onBlur?.(e);
                  }}
                />
              </Field>
              <Legend error={error} raiseLabel={raiseLabel}>
                {label}
              </Legend>
              {(icon || error) && (
                <span className="wrapper-icon">
                  <button type="button" className="icon" onClick={onIconClick}>
                    {error && !isErrorIconDisabled ? <ErrorIcon /> : icon}
                  </button>
                </span>
              )}
            </div>
          </Fieldset>
        </FieldsetWrap>
      )}
      {!isErrorMessageDisabled && error && error !== ' ' && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
});

export default MaskInput;

