import styled, { css } from 'styled-components';
import { colors, cssprops, getTypography, pxToRem } from '@vfit/shared/themes';
import { IInputsForSC } from './inputs.models';

export const inputsVariants = {
  inputOrTextarea: {
    animated: css<IInputsForSC>`
      border-color: ${({ error }) => (!error ? colors.$bebebe : colors.$bd0000)};
      border-radius: ${pxToRem(5)};

      &::placeholder {
        opacity: 0;
      }

      &:focus + span,
      &:not(:placeholder-shown) + span {
        ${getTypography('body3.regular')}
        color: ${({ disabled }) => (!disabled ? colors.$262626 : colors.$7e7e7e)};
        transform: translate3d(0, ${pxToRem(-27)}, 0);
      }
    `,
    'mva:animated': css<IInputsForSC>`
      border-color: ${({ error }) => (!error ? colors.$bebebe : colors.$bd0000)};
      border-radius: ${pxToRem(6)};
      ${({ error }) => (error ? `box-shadow: ${pxToRem(0, 0, 0, 1)} ${colors.$bd0000};` : '')}

      &::placeholder {
        opacity: 0;
      }

      &:focus {
        border-color: ${colors.$0096AD};
        box-shadow: ${pxToRem(0, 0, 0, 1)} ${colors.$0096AD};
      }

      &:focus + span,
      &:not(:placeholder-shown) + span {
        ${getTypography('body3.regular')}
        color: ${({ disabled }) => (!disabled ? colors.$262626 : colors.$7e7e7e)};
        transform: translate3d(0, ${pxToRem(-19)}, 0);
      }
    `,
    'mva:static': css<IInputsForSC>`
      border-color: ${({ error }) => (!error ? colors.$bebebe : colors.$bd0000)};
      border-radius: ${pxToRem(6)};
      ${({ error }) => (error ? `box-shadow: ${pxToRem(0, 0, 0, 1)} ${colors.$bd0000};` : '')}

      &::placeholder {
        color: ${colors.$7e7e7e};
        opacity: 1;
      }

      &:focus {
        border-color: ${colors.$0096AD};
        box-shadow: ${pxToRem(0, 0, 0, 1)} ${colors.$0096AD};
      }
    `,
  },
};

const variants = {
  container: {
    animated: css`
      padding-top: ${pxToRem(9)};
      padding-bottom: ${pxToRem(24)};
    `,
    'mva:animated': css`
      padding-top: ${pxToRem(9)};
      padding-bottom: ${pxToRem(27)};
    `,
    'mva:static': css`
      padding-top: ${pxToRem(36)};
      padding-bottom: ${pxToRem(27)};
    `,
  },
  input: {
    animated: css<IInputsForSC>`
      ${inputsVariants.inputOrTextarea.animated}
      height: ${pxToRem(60)};
      padding: ${({ svg }) => (!svg ? pxToRem(18, 20) : pxToRem(18, 56, 18, 20))};
    `,
    'mva:animated': css<IInputsForSC>`
      ${inputsVariants.inputOrTextarea['mva:animated']}
      height: ${pxToRem(44)};
      padding: ${({ svg }) => (!svg ? pxToRem(9, 15) : pxToRem(9, 54, 9, 15))};
    `,
    'mva:static': css<IInputsForSC>`
      ${inputsVariants.inputOrTextarea['mva:static']}
      height: ${pxToRem(44)};
      padding: ${({ svg }) => (!svg ? pxToRem(9, 15) : pxToRem(9, 54, 9, 15))};
    `,
  },
  labelPlaceholder: {
    animated: css<IInputsForSC>`
      ${getTypography('body1.regular')}
      border-radius: ${pxToRem(5)};
      color: ${colors.$7e7e7e};
      left: ${pxToRem(13)};
      padding: ${pxToRem(0, 8)};
      top: ${pxToRem(18)};
    `,
    'mva:animated': css<IInputsForSC>`
      ${getTypography('body1.regular')}
      border-radius: ${pxToRem(6)};
      color: ${colors.$7e7e7e};
      left: ${pxToRem(12)};
      padding: ${pxToRem(0, 4)};
      top: ${pxToRem(10)};
    `,
    'mva:static': css<IInputsForSC>`
      ${getTypography('body1.regular')}
      color: ${({ disabled }) => (!disabled ? colors.$262626 : colors.$7e7e7e)};
      background-color: transparent;
      left: 0;
      padding: ${pxToRem(2, 0)};
      top: ${pxToRem(-36)};
    `,
  },
};

export const input = css<IInputsForSC>`
  ${getTypography('body1.regular')}
  border-width: 1px;
  border-style: solid;
  color: ${({ disabled }) => (!disabled ? colors.$262626 : colors.$7e7e7e)};
  outline: 0; // TODO This is a bad practice
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;

  ::-ms-reveal,
  ::-ms-clear {
    display: none;
  }

  &::file-selector-button {
    display: none;
  }

  ${({ bg }) => (bg ? `background-color: ${bg};` : '')};
  ${({ variant }) => (variant ? variants.input[variant] : '')}
`;

export const checkbox = css`
  align-items: center;
  appearance: none;
  border: 1px solid ${colors.$bebebe};
  border-radius: ${pxToRem(3)};
  display: inline-flex;
  flex-shrink: 0;
  height: ${pxToRem(20)};
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s ease;
  width: ${pxToRem(20)};

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 9'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M11 1 4.158 8 1 4.77'/%3E%3C/svg%3E");
    content: '';
    display: block;
    height: ${pxToRem(9)};
    width: ${pxToRem(12)};
  }

  &:checked {
    background-color: ${colors.$00697c};
    border-color: ${colors.$00697c};
  }
`;

export const radio = css`
  align-items: center;
  appearance: none;
  border: 1px solid ${colors.$bebebe};
  border-radius: 50%;
  display: inline-flex;
  flex-shrink: 0;
  height: ${pxToRem(20)};
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s ease;
  width: ${pxToRem(20)};

  &::before {
    border-color: ${colors.$00697c};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${pxToRem(12)};
    transition: background-color 0.3s ease;
    width: ${pxToRem(12)};
  }

  &:checked::before {
    background-color: ${colors.$00697c};
    border-color: ${colors.$00697c};
  }

  &:checked {
    border-color: ${colors.$00697c};
  }
`;

export const container = css<IInputsForSC & cssprops.IStyled>`
  ${({ hidden }) => (hidden ? 'visibility: hidden;' : '')}
  position: relative;
  ${({ variant }) => (variant ? variants.container[variant] : '')}
  ${cssprops.styled}
`;

export const Container = styled.div<IInputsForSC & cssprops.IStyled>`
  ${container}
`;

export const Label = styled.label`
  display: block;
  position: relative;
  width: 100%;
  z-index: 1;
`;

export const LabelPlaceholder = styled.span<IInputsForSC>`
  pointer-events: none;
  position: absolute;
  transition: all 0.3s ease;
  ${({ bg }) => (bg ? `background-color: ${bg};` : '')};
  ${({ variant }) => (variant ? variants.labelPlaceholder[variant] : '')}
`;
