import React, { Children } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SlideCarousel } from '@vfit/shared/components';
import { EffectCards, FreeMode, Keyboard, Mousewheel, Pagination } from 'swiper';
import { useDeviceType } from '@vfit/shared/hooks';
import { SwiperCardsContainer } from './swiperCards.style';
import { getSlideSize } from '../../sliderCarousel.utils';
import { ISwiperCardsProps } from './swiperCards.models';

const SwiperCards = ({
  carouselSlides,
  isPromo,
  isCovered,
  forceTextTop,
  isExperiences,
  disableCursor,
  showCase,
  onTrack,
  SlideCarouselComponent = SlideCarousel,
}: ISwiperCardsProps) => {
  const { isMobile, isTablet, isDesktop, isBigDesktop } = useDeviceType();
  const bulletClass = isExperiences ? 'swiper-pagination-bullet-exp' : 'swiper-pagination-bullet';
  const bulletActiveClass = isExperiences
    ? 'swiper-pagination-bullet-active-exp'
    : 'swiper-pagination-bullet-active';
  const alignCenter =
    (!isPromo && isBigDesktop && carouselSlides?.length === 3) ||
    (isPromo && isBigDesktop && carouselSlides?.length === 2);
  const fewSlide =
    (!isPromo && carouselSlides?.length === 3) || (isPromo && carouselSlides?.length === 2);
  const centeredSlide = isMobile && !isCovered;
  const isCardsType = isMobile && !isCovered && !isExperiences;

  const lastCard = () => (
    <SwiperSlide
      style={{
        width: 1,
        height: 1,
      }}
    />
  );

  const swiperProduct = (type: 'slide' | 'cards') => (
    <Swiper
      slidesPerView="auto"
      effect={type}
      cardsEffect={{
        rotate: true,
        slideShadows: false,
      }}
      spaceBetween={26}
      freeMode={isMobile && isCovered}
      mousewheel={{
        forceToAxis: true,
      }}
      centeredSlides={centeredSlide}
      keyboard
      pagination={{
        enabled: !disableCursor && !fewSlide,
        bulletClass,
        bulletActiveClass,
        clickable: true,
      }}
      modules={[Pagination, FreeMode, Mousewheel, Keyboard, EffectCards]}
    >
      {Children.toArray(
        carouselSlides?.map((slide, index) => {
          // eslint-disable-next-line no-nested-ternary
          let marginLeft = index === 0 ? (isDesktop ? 80 : 24) : 0;
          if (index === 0 && isMobile) {
            if (isCovered) marginLeft = 24;
            else marginLeft = 0;
          }
          const customSize = getSlideSize(
            index,
            isCovered || false,
            isExperiences || false,
            isPromo || false,
            isMobile,
            isTablet
          );
          const isEnabledOnClickAction = isMobile && (isCovered || isExperiences || isPromo);
          const positionTextTop = slide?.positionTextTop === 'true';
          // let textBottom = forceTextTop ? false : !positionTextTop || isCovered || isPromo;
          const textPosition = () => {
            if (forceTextTop) return false;
            if (positionTextTop) return false;
            return isCovered || isPromo;
          };
          return (
            <SwiperSlide
              style={{
                marginLeft,
                transform: 'translateZ(0)',
                ...customSize,
              }}
            >
              <SlideCarouselComponent
                onTrack={onTrack}
                position={index}
                showCase={showCase}
                slide={{
                  ...slide,
                  ...customSize,
                }}
                textBottom={textPosition()}
                zoomIn={!!(!isMobile || isCovered)}
                coveredText={isCovered}
                defaultSize={customSize}
                isEnabledOnClickAction={isEnabledOnClickAction}
              />
            </SwiperSlide>
          );
        })
      )}
      {!isMobile && lastCard()}
    </Swiper>
  );

  return (
    <SwiperCardsContainer alignCenter={alignCenter}>
      {isCardsType && swiperProduct('cards')}
      {!isCardsType && swiperProduct('slide')}
    </SwiperCardsContainer>
  );
};

export default SwiperCards;
