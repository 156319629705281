import React, { Children, useState } from 'react';
import { useDeviceType } from '@vfit/shared/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel, Keyboard, EffectCards, Thumbs } from 'swiper';
import { ImageAtoms } from '@vfit/shared/atoms';
import { ThumbnailSlider } from './thumbnailSwiper.style';

const ThumbnailSwiper = ({ images, externalUrl }: { images?: string[]; externalUrl?: boolean }) => {
  const { isMobile } = useDeviceType();
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  return (
    <ThumbnailSlider>
      <Swiper
        slidesPerView="auto"
        spaceBetween={10}
        effect="slide"
        cardsEffect={{
          rotate: true,
          slideShadows: false,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        freeMode={false}
        centeredSlides={isMobile}
        keyboard
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Mousewheel, Keyboard, EffectCards, Thumbs]}
        className="selected-thumbnail-swiper"
      >
        {Children.toArray(
          images?.map((img, index) => {
            return (
              <SwiperSlide
                style={{
                  borderRadius: '0px',
                  height: 375,
                  // width: 375,
                }}
              >
                <ImageAtoms
                  key={img}
                  nameCard={`device_slide${index}`}
                  image={img}
                  external={externalUrl}
                  type={externalUrl ? 'default' : 'lambda'}
                />
              </SwiperSlide>
            );
          })
        )}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        slidesPerView="auto"
        effect="slide"
        cardsEffect={{
          rotate: true,
          slideShadows: false,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        spaceBetween={8}
        freeMode={false}
        keyboard
        watchSlidesProgress
        modules={[FreeMode, Mousewheel, Keyboard, EffectCards, Thumbs]}
        className="all-thumbnail-swiper"
      >
        {Children.toArray(
          images?.map((img, index) => (
            <SwiperSlide
              style={{
                borderRadius: '8px',
                height: 88,
                width: 88,
              }}
            >
              <ImageAtoms
                key={img}
                nameCard={`device_slide${index}`}
                image={img}
                external={externalUrl}
                type={externalUrl ? 'default' : 'lambda'}
              />
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </ThumbnailSlider>
  );
};

export default ThumbnailSwiper;
