import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const ImageDividerContainer = styled.div<{
  enableMaxWidth?: boolean;
}>`
  text-align: center;
  margin-top: 0;
  margin-bottom: 64px;

  img {
    max-width: ${({ enableMaxWidth }) => (enableMaxWidth ? '100%' : 'inherit')};
    max-height: 150px;
    ${({ enableMaxWidth }) => (enableMaxWidth ? 'object-fit: contain !important' : '')};
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 80px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 120px;
  }
`;

