import styled from 'styled-components';
import { colors, fonts } from '@vfit/shared/themes';

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }
`;

export const ModalTitle = styled.div`
  div {
    margin: 0;
    margin-bottom: 24px;
  }
`;

export const ButtonContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  button {
    margin: 0 auto;
    width: auto;
    max-width: 400px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
    }
  }
  white-space: nowrap;
`;

export const ModalContent = styled.div`
  div {
    margin: 0;
  }
`;

