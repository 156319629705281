import styled from 'styled-components';
import { colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
`;

export const Input = styled.input`
  display: inline-block;
  text-align: center;
  font-family: ${fonts.exbold};
  ${pxToCssFont(24, 28)}
  height: 30px;
  width: 24px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 1px;
  border-color: ${colors.$262626};
  padding: 0;

  &[value=''] {
    border-color: ${colors.$bebebe};
  }

  :focus {
    outline: none;
    border-color: ${colors.$262626};
  }

  :disabled {
    background-color: transparent;
  }
`;

export const ErrorMessage = styled.p`
  position: relative;
  animation: fadein 2s;
  color: ${colors.$e60000};
  ${pxToCssFont(14, 0)}
  font-family: ${fonts.regular};
  text-align: center;

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

