import React from 'react';
import { CustomText, Button } from '@vfit/shared/atoms';
import { getVariantByActionStyle } from '@vfit/shared/data-access';
import { Container, TableContainer, ButtonContainer, TableRow } from './cardTable.style';
import { ICardTable } from './cardTable.model';

const CardTable: React.FC<ICardTable> = ({
  tableRows,
  action,
  actionClick,
  actionNavigationUrl,
}) => (
  <Container>
    {tableRows && (
      <TableContainer>
        {tableRows.map((row) => (
          <TableRow
            key={Math.random().toString()}
            isFirstRow={row.isFirstRow}
            isLastRow={row.isLastRow}
          >
            <CustomText
              text={row.label}
              textAlign="left"
              size={18}
              sizeMobile={18}
              lineHeight={24}
              lineHeightMobile={24}
            />
            <CustomText
              text={row.value}
              textAlign="right"
              size={18}
              sizeMobile={18}
              lineHeight={24}
              lineHeightMobile={24}
            />
          </TableRow>
        ))}
      </TableContainer>
    )}
    {action?.title && actionClick && (
      <ButtonContainer>
        <Button
          onClick={() => !actionNavigationUrl && actionClick()}
          href={actionNavigationUrl}
          target={actionNavigationUrl && action?.isBlank === 'true' ? '_blank' : undefined}
          variant={getVariantByActionStyle(action?.style)}
          name={`action_cardTable_${action?.type}`}
        >
          {action?.title}
        </Button>
      </ButtonContainer>
    )}
  </Container>
);
export default CardTable;

