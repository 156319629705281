import type { SVGProps } from 'react';
import { memo } from 'react';

const ErrorIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      color="rgba(230, 0, 0, 1)"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6337 15.045L12.6337 2.08126C12.3754 1.6087 11.9947 1.21437 11.5315 0.939661C11.0683 0.664951 10.5397 0.519989 10.0012 0.519989C9.46265 0.519989 8.93405 0.664951 8.47085 0.939661C8.00766 1.21437 7.62695 1.6087 7.36868 2.08126L0.368683 15.04C0.118733 15.4968 -0.00800601 16.0108 0.000930159 16.5315C0.00986633 17.0521 0.15417 17.5615 0.419649 18.0095C0.685128 18.4574 1.06264 18.8286 1.51505 19.0864C1.96747 19.3443 2.4792 19.4799 2.99993 19.48H16.9999C17.5202 19.4799 18.0314 19.3445 18.4835 19.0871C18.9356 18.8297 19.313 18.4591 19.5786 18.0118C19.8442 17.5645 19.989 17.0558 19.9986 16.5357C20.0083 16.0156 19.8825 15.5019 19.6337 15.045ZM9.49993 5.98251C9.49993 5.8499 9.55261 5.72272 9.64638 5.62896C9.74015 5.53519 9.86732 5.48251 9.99993 5.48251C10.1325 5.48251 10.2597 5.53519 10.3535 5.62896C10.4473 5.72272 10.4999 5.8499 10.4999 5.98251V13.045C10.4999 13.1776 10.4473 13.3048 10.3535 13.3986C10.2597 13.4923 10.1325 13.545 9.99993 13.545C9.86732 13.545 9.74015 13.4923 9.64638 13.3986C9.55261 13.3048 9.49993 13.1776 9.49993 13.045V5.98251ZM9.99993 16.1075C9.87632 16.1075 9.75548 16.0709 9.6527 16.0022C9.54992 15.9335 9.46981 15.8359 9.42251 15.7217C9.3752 15.6075 9.36283 15.4818 9.38694 15.3606C9.41106 15.2393 9.47058 15.128 9.55799 15.0406C9.6454 14.9532 9.75676 14.8936 9.878 14.8695C9.99924 14.8454 10.1249 14.8578 10.2391 14.9051C10.3533 14.9524 10.4509 15.0325 10.5196 15.1353C10.5883 15.2381 10.6249 15.3589 10.6249 15.4825C10.6239 15.6476 10.5577 15.8056 10.4406 15.922C10.3235 16.0384 10.165 16.1038 9.99993 16.1038V16.1075Z"
        fill="#e60000"
      />
    </svg>
  );
};

export default memo<SVGProps<SVGSVGElement>>(ErrorIcon);
