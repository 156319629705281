import { useEffect, useState } from 'react';

const MIN_RESIZE_DIFFERENCE = 200;

export const useViewportHeight = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);

  useEffect(() => {
    const element = document.getElementById('mainHeader');
    if (element?.clientHeight) {
      setHeaderHeight(element?.clientHeight);
    }
  }, []);

  useEffect(() => {
    const setVh = () => {
      if (
        Math.abs(viewportHeight - window.innerHeight) > MIN_RESIZE_DIFFERENCE &&
        window.scrollY === 0
      ) {
        requestAnimationFrame(() => {
          setViewportHeight(window.innerHeight);
        });
      }
    };

    setViewportHeight(window.innerHeight);

    window.addEventListener('resize', setVh);

    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, [viewportHeight]);

  return {
    headerHeight,
    viewportHeight,
  };
};
