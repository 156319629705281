import { colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const SuggestionItem = styled.li`
  list-style: none;
  padding: 8px 20px;
  color: ${colors.$7e7e7e};
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(18, 24)}
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
    background-color: #ccc;
  }
`;

