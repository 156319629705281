import { Shadow } from '@vfit/shared/atoms';
import { Container } from './shadowsGroup.style';

export const ShadowsGroup = ({ quantity = 5, heights = 45, space = 34 }) => {
  return (
    <Container itemsDistance={space}>
      {Array.from(Array(quantity).keys()).map((e) => (
        <Shadow key={e} height={heights} />
      ))}
    </Container>
  );
};

export default ShadowsGroup;
