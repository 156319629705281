import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import {
  ISlideCarouelContainerImageCSSProps,
  ISlideCarouelContainerVideoCSSProps,
} from '@vfit/shared/models';

export const SlideCarouselContainerImage = styled.div<ISlideCarouelContainerImageCSSProps>`
  position: absolute;
  width: ${({ width }) => `${width ? `${width}px` : '100%'}`};
  height: ${({ height }) => `${height ? `${height}px` : '100%'}`};
  border-radius: 20px;
  z-index: 0;

  span {
    border-radius: 20px;
  }

  overflow: hidden;
  cursor: pointer;
  transition: transform 0.9s ease-in-out;
`;

export const SlideCarouselContainerVideo = styled.div<ISlideCarouelContainerVideoCSSProps>`
  position: absolute;
  width: ${({ width }) => `${width ? `${width}px` : '100%'}`};
  height: ${({ height }) => `${height ? `${height}px` : '100%'}`};
  border-radius: 20px;
  transform: translateZ(0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1%), rgba(0, 0, 0, 1%)),
    linear-gradient(178.32deg, rgba(0, 0, 0, 5%) 9.21%, rgba(0, 0, 0, 0%) 56.26%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  span {
    border-radius: 20px;
  }

  div {
    position: relative;
    width: 100%;
    height: 100%;
  }

  div::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 1%), rgba(0, 0, 0, 1%)),
      linear-gradient(178.32deg, rgba(0, 0, 0, 5%) 9.21%, rgba(0, 0, 0, 0%) 56.26%);
  }

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  overflow: hidden;
  cursor: pointer;
`;

export const SlideCarouselNoMaskTop = styled.div`
  width: 100%;
  flex: 0.8;
`;

export const SlideCarouselNoMaskTopChild = styled.div`
  position: absolute;
  padding-left: 32px;
  padding-top: 40px;
  max-width: 84%;
  cursor: pointer;

  h2 {
    font-family: ${fonts.regular};
    ${pxToCssFont(18, 24)}
    color: #ececec;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 10px;
  }

  h3 {
    font-family: ${fonts.exbold};
    ${pxToCssFont(35, 38)}
    color: ${colors.$ffffff};
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    font-family: ${fonts.light};
    margin-top: 8px;
    margin-bottom: 0;
    ${pxToCssFont(20, 26)}
    color: ${colors.$ffffff};
  }

  @media (min-width: ${breakpoints.tablet}) {
    h3 {
      ${pxToCssFont(35, 38)}
    }

    p {
      margin-top: 8px;
      margin-bottom: 0;
      font-family: ${fonts.light};
      ${pxToCssFont(20, 26)}
      color: ${colors.$ffffff};
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    h2 {
      ${pxToCssFont(18, 24)}
    }

    h3 {
      ${pxToCssFont(42, 52)}
    }

    p {
      font-family: ${fonts.light};
      margin-top: 16px;
      margin-bottom: 0;
      ${pxToCssFont(24, 30)}
      color: ${colors.$ffffff};
    }
  }
`;

export const SlideCarouselNoMaskBottom = styled.div`
  width: 100%;
  position: absolute;
  bottom: 40px;
`;

export const SlideCarouselNoMaskBottomChild = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  cursor: pointer;
`;

export const SlideCarouselNoMaskBottomChildText = styled.div<{
  coveredText: boolean;
  paddingBottom?: boolean;
}>`
  display: flex;
  max-width: 80%;
  margin-left: 38px;
  margin-right: 10px;
  flex-direction: column;
  cursor: pointer;

  .title-emotionalCard {
    margin-bottom: ${({ paddingBottom }) => (paddingBottom ? '21px' : 0)};
    @media (min-width: ${breakpoints.tablet}) {
      margin-bottom: ${({ paddingBottom }) => (paddingBottom ? '24px' : 0)};
    }
    @media (min-width: ${breakpoints.desktop}) {
      margin-bottom: ${({ paddingBottom }) => (paddingBottom ? '32px' : 0)};
    }
  }

  h2 {
    font-family: ${fonts.regular};
    ${pxToCssFont(18, 24)}
    color: #ececec;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }

  h3 {
    font-family: ${fonts.exbold};
    ${pxToCssFont(35, 38)}
    color: ${colors.$ffffff};
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
  }

  p {
    margin-top: 8px;
    margin-bottom: 21px;
    font-family: ${fonts.light};
    ${pxToCssFont(20, 26)}
    font-weight: 400;
    color: ${colors.$ffffff};
  }

  @media (min-width: ${breakpoints.tablet}) {
    p {
      margin-bottom: 24px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    h2 {
      ${pxToCssFont(18, 24)}
    }

    h3 {
      ${({ coveredText }) => (!coveredText ? pxToCssFont(42, 52) : pxToCssFont(36, 50))}
    }

    p {
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }
`;

export const SlideCarouselOverlayImage = styled.div`
  margin-bottom: 32px;
  margin-left: 32px;
  object-fit: contain;
  cursor: pointer;

  img {
    object-fit: cover !important;
    width: inherit !important;
  }
`;

export const SlideCarouselNoMaskBottomButton = styled.div<{ isSmall: boolean }>`
  width: 100%;
  text-align: center;
  margin-left: ${({ isSmall }) => (isSmall ? '38px' : '32px')};
  margin-right: 32px;

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 32px;
    margin-right: 32px;
    text-align: inherit;
  }
`;

export const SlideCarouselMaskTop = styled.div`
  width: 100%;
  flex: 0.75;
`;

export const SlideCarouselMaskBottom = styled.div`
  width: 100%;
  min-height: 112px;
  background: rgba(53, 53, 53, 40%);
  position: absolute;
  bottom: 0;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  backdrop-filter: blur(35.0897px);
`;

export const SlideCarouselMaskBottomChild = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

export const SlideCarouselMaskBottomChildText = styled.div<{ withImage: boolean }>`
  flex: ${({ withImage }) => (withImage ? 0.74 : 'inherit')};
  cursor: pointer;
  align-self: center;
  margin-bottom: 20px;

  h3 {
    ${pxToCssFont(20, 26)}
    font-family: ${fonts.regular};
    margin: 20px 0 0 20px;
    color: ${colors.$ffffff};
    font-weight: 700;
  }

  p {
    font-family: ${fonts.regular};
    font-weight: 400;
    margin: 6px 3px 0 20px;
    ${pxToCssFont(16, 22)}
    color: ${colors.$ffffff};
  }

  @media (min-width: ${breakpoints.desktop}) {
    h3 {
      font-family: ${fonts.exbold};
      font-weight: 400;
      ${pxToCssFont(24, 30)}
    }
  }
`;

export const SlideCarouselMaskBottomChildIcon = styled.div`
  flex: 0.26;
  align-self: center;
  text-align: center;

  img {
    object-fit: contain;
  }
`;

export const DivImgContainer = styled.div<{ absolute: boolean; zoomIn?: boolean }>`
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  z-index: -1;
  object-fit: cover;
  object-position: center;
  border-radius: 15px 15px 0 0;
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: ${(props) => (props.absolute ? 0 : '')};
  left: ${(props) => (props.absolute ? 0 : '')};

  img {
    transition: transform 0.9s ease-in-out;
  }

  &:hover {
    img {
      transform: ${({ zoomIn }) => (zoomIn ? 'scale(1.5)' : 'inherit')};
    }
  }
`;

