import { breakpoints, colors, fonts } from '@vfit/shared/themes';
import styled from 'styled-components';

export const ThumbnailSlider = styled.div`

  .all-thumbnail-swiper .swiper-slide {
    border: 1px solid ${colors.$bebebe};
  }

  .all-thumbnail-swiper .swiper-slide-thumb-active {
    border: 1px solid ${colors.$0096AD};
  }

  @media (min-width: ${breakpoints.tablet}) {
  }

  @media (min-width: ${breakpoints.desktop}) {
  }
`;
