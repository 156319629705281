import styled from 'styled-components';
import { animations, pxToRem, gradients } from '@vfit/shared/themes';
import { IShadow } from './shadow.models';

export const Shadow = styled.div<IShadow>`
  background: ${gradients.skeleton_shadow};
  animation: ${animations.shadowLoad} 1.5s infinite;
  min-height: ${({ height }) => (height ? pxToRem(height) : pxToRem(45))};
  ${({ width }) => (width ? `width : ${width};` : '')}
`;
