import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import { ICSSProps } from './textAreaInput.model';

export const ErrorMessage = styled.p`
  position: absolute;
  animation: fadein 2s;
  color: ${colors.$e60000};
  ${pxToCssFont(14, 0)}
  font-family: ${fonts.regular};
  text-align: left;

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const Fieldset = styled.div<ICSSProps>`
  position: relative;
  margin: 0;
  margin-top: 9.008px;
  padding: 18px 16px 18px 20px;
  display: flex;
  transition: border 2s;
  border-radius: 5.008px;
  background: transparent;
  ${(props) => {
    if (props.error) {
      return `border: 1.008px solid ${colors.$e60000}`;
    }
    return props.isDisabled ? `border: 1.008px solid #BEBEBE` : `border: 1.008px solid #BEBEBE`;
  }};

  .wrapper {
    display: inline-flex;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .wrapper-icon {
    display: flex;

    @media (min-width: ${breakpoints.mobile}) {
      align-self: center;
    }

    @media (min-width: ${breakpoints.tablet}) {
      align-self: center;
    }
  }

  .icon {
    margin: auto;
    display: flex;
    flex-direction: column;
    appearance: none;
    background: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    padding: 0;

    &:hover {
      cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    }
  }

  ${(props) => (props.isDisabled ? '&:hover {cursor: not-allowed}' : '')}
`;

// div for inputs
export const Field = styled.textarea<ICSSProps>`
  resize: none;
  height: 25.008px;
  margin: auto;
  width: 100%;
  outline: 0;
  border: 0;
  text-overflow: ellipsis;
  ${pxToCssFont(18, 24)}
  text-align: left;

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.$262626};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: auto;
    height: 28px;
  }
`;

export const Legend = styled.label<ICSSProps>`
  ${(props) => (props.error ? `color: ${colors.$e60000};` : `color: ${colors.$7e7e7e}`)};
  height: 18px;
  padding: 0 8px;
  margin-left: -5.008px;
  margin-top: 3.008px;
  transition: 0.2s;
  background: white;
  ${pxToCssFont(18, 24)}
  font-family: ${fonts.regular};
  text-align: left;
  position: absolute;

  ${Field}:not(:placeholder-shown) ~ &,
  ${Field}:focus ~ & {
    transition: 0.2s;
    top: -13.008px;
    ${pxToCssFont(14, 18)}
    z-index: 2;
    color: ${colors.$262626};
  }
`;

