import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.$bebebe};
  border-radius: 20px;
  background-color: ${colors.$ffffff};
`;

export const TableContainer = styled.div`
  margin-bottom: 32px;
`;

export const TableRow = styled.div<{ isFirstRow?: boolean; isLastRow?: boolean }>`
  padding: ${({ isFirstRow, isLastRow }) => {
    if (isFirstRow) return '0 0 16px 0';
    else if (isLastRow) return '16px 0 0 0';
    else return '16px 0';
  }};
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ isLastRow }) => (isLastRow ? 'none' : '1px solid #262626')};
  div {
    margin: 0;
    max-width: 141px;
    p {
      margin: 0;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    div {
      max-width: none;
    }
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  button,
  a {
    margin: 0 auto;
    max-width: 320px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
    }
  }
  white-space: nowrap;
`;

