import { colors } from '@vfit/shared/themes';
import { IPillProps } from './pill.models';
import { PillContainer } from './pill.style';

const Pill = ({ label = 'defaultLabel', pillsColor = colors.$ffffff, opacity = 0.8, borderColor }: IPillProps) => (
  <PillContainer pillsColor={pillsColor} opacity={opacity} borderColor={borderColor}>
    <span style={{ color: pillsColor === colors.$ffffff ? colors.$262626 : colors.$ffffff }}>{label}</span>
  </PillContainer>
);

export default Pill;
