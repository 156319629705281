import {
  useState,
  useCallback,
  ChangeEventHandler,
  forwardRef,
  ForwardedRef,
  useEffect,
  useRef,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from 'react';
import { SelectIcon } from '@vfit/shared-icons';
import TextInput from '../TextInput/textInput';
import { ISelectInputProps } from './selectInput.models';
import { Wrapper, Suggestions, SuggestionItem, FillBorder } from './selectInput.style';

function useOutsideAlerter(
  ref: MutableRefObject<any>,
  setShowOptions: Dispatch<SetStateAction<boolean>>
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

/** SELECTINPUT DOCS
 * @description This component aims to display an array of strings passed from "props.items" as a list below the
 * TextInput
 * @param props -
 * - label : string --> (REQUIRED) legend on top of Input
 * - items : string[] --> (REQUIRED) array of strings for options
 * - isDisabled : boolean --> to handle disabled view
 * @author Andrea
 */
const SelectInput = forwardRef<HTMLInputElement, ISelectInputProps>(
  (props: ISelectInputProps, ref: ForwardedRef<any>) => {
    const {
      label,
      setValue,
      placeholder,
      items,
      onSelectValue,
      isDisabled,
      isErrorMessageDisabled,
      isErrorIconDisabled,
      errorMessage,
      data,
      hidden = false,
      suggestionsMaxHeight,
      getIsExistedError,
    } = props;

    //  const [value] = useState<string>('');
    // const { label, placeholder, items, onSelectValue, isDisabled, hidden = false, ...rest } = props;
    // const [value, setValue] = useState<string>('');

    const [_valueManualEntered, setManualValue] = useState(' ');
    const [filteredItems, setFilteredItems] = useState<string[]>(items);
    const [showOptions, setShowOptions] = useState<boolean>(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setShowOptions);

    useEffect(() => {
      setFilteredItems(items);
    }, [items]);

    /** FILTER HELPER
     * @description It filters props.items based on the matching with value
     * @param val the value to be searched
     * @returns filtered array copy
     * @author Andrea
     */

    /* const filterHelper = (val: string): string[] =>
    items.filter((s) => s.toLowerCase().includes(val.toLocaleLowerCase())); */
    const filterHelper = useCallback(
      (val: string): string[] =>
        items?.filter((s) => s.toLowerCase().includes(val.toLocaleLowerCase())),
      [items]
    );

    /** SUGGESTION HANDLER
     * @description If the target.value is null, it sets the value to null string, the filteredItems back to the props.items
     * and it toggles the suggestions' visibility.
     * If the target.value exists, it sets the value, the filteredItems starting from items
     * and it toggles the suggestions' visibility.
     * @param event from Input onChange event handler
     * @returns
     * @author Andrea
     */
    const suggestionsHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        if (!event.target.value) {
          setManualValue('');
          onSelectValue('');
          if (setValue) setValue('');
          // onSelectValue('')
          setFilteredItems(items);
          setShowOptions(false);
        } else {
          if (setValue) setValue(event.target.value);
          setManualValue(event.target.value);
          const filteredData = filterHelper(event.target.value);
          setFilteredItems(filteredData);
          setShowOptions(true);
          if (getIsExistedError) {
            getIsExistedError(true);
          }
        }
      },
      [filterHelper, getIsExistedError, items, onSelectValue, setValue]
    );

    /** SHOW OPTIONS HANDLER
     * @description Setter to handle suggestions' visibility
     * @param
     * @returns
     * @author Andrea
     */

    const showOptionsHandler = (): void => {
      if (!isDisabled) {
        if (showOptions) {
          setShowOptions(false);
        } else {
          setShowOptions(true);
        }
      }
    };

    /** SELECTION HANDLER
     * @description It sets the Input value and toggle the options' view
     * @param element selected option
     * @returns
     * @author Andrea
     */

    // interface IError  {
    //   element?: string;
    // }

    const errorHandler = () => {
      if (filteredItems?.length === 0) {
        return 'Nessuna opzione disponibile';
      }
      return '';
    };

    const selectionHandler = (element: string): void => {
      if (setValue) setValue(element);
      if (element) onSelectValue(element);
      setShowOptions(false);
    };

    useEffect(() => {
      if (data && setValue) setValue(data);
    }, [data, setValue]);

    return (
      <div>
        {!hidden && (
          <div ref={wrapperRef}>
            <TextInput
              onKeyDown={(e) => {
                if (e.code === 'Backspace') {
                  setManualValue('');
                  onSelectValue('');
                  if (setValue) setValue('');
                  setFilteredItems(items);
                  setShowOptions(false);
                }
              }}
              placeholder={placeholder}
              error={errorMessage || errorHandler()}
              label={label}
              ref={ref}
              isErrorMessageDisabled={isErrorMessageDisabled}
              isErrorIconDisabled={isErrorIconDisabled}
              onFocus={showOptionsHandler}
              onChange={suggestionsHandler}
              onIconClick={showOptionsHandler}
              disabled={isDisabled}
              icon={<SelectIcon />}
              //  {...rest}
            />
            {filteredItems && showOptions && !isDisabled && (
              <>
                {filteredItems.length > 0 && <FillBorder />}
                <Wrapper>
                  <Suggestions
                    isError={filteredItems.length === 0}
                    suggestionsMaxHeight={suggestionsMaxHeight}
                  >
                    {filteredItems.map((el) => (
                      <SuggestionItem
                        key={Math.random().toString()}
                        onClick={() => {
                          selectionHandler(el);
                        }}
                      >
                        {el}
                      </SuggestionItem>
                    ))}
                  </Suggestions>
                </Wrapper>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default SelectInput;
