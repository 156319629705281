import { ICustomSize, ICustomSizeValues } from '@vfit/shared/models';

const DEFAULT_SIZE: ICustomSize = {
  default: {
    width: 438,
  },
  mobile: {
    width: 332,
  },
  tablet: {
    width: 353,
  },
};

/**
 * Return the size of slide
 * @param slideIndex
 * @param isMobile
 * @param isTablet
 */
const getSlideSize = (
  slideIndex: number,
  isMobile: boolean,
  isTablet: boolean
): ICustomSizeValues => {
  if (isTablet && DEFAULT_SIZE.tablet) return DEFAULT_SIZE.tablet;
  if (isMobile) return DEFAULT_SIZE.mobile;
  return DEFAULT_SIZE.default;
};

const updateAllHeight = (ref: HTMLDivElement) => {
  const pagination = ref.getElementsByClassName('swiper-pagination');
  (pagination[0] as HTMLElement).style.marginBottom = '42px';
  const allSlides = ref.getElementsByClassName('swiper-slide');
  const allHeight = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < allSlides.length; i++) {
    const bottomCard = allSlides[i].getElementsByClassName('bottom-container');
    if (bottomCard?.[0]) {
      allHeight.push(bottomCard[0].clientHeight);
    }
  }
  const maxH = Math.max(...allHeight);
  if (maxH) {
    for (let i = 0; i < allSlides.length; i += 1) {
      const bottomCard = allSlides[i].getElementsByClassName('bottom-container');
      if (bottomCard?.[0]) {
        (bottomCard[0] as HTMLDivElement).style.height = `${maxH}px`;
      }
    }
  }
};

export { getSlideSize, updateAllHeight, DEFAULT_SIZE };
