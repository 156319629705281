import { ForwardedRef, forwardRef, useEffect } from 'react';
import { Fieldset, Field, ErrorMessage, FieldsetContainer } from './codeInput.style';
import { ICodeInputProps } from './codeInput.models';

const CodeInput = forwardRef<HTMLInputElement, ICodeInputProps>(
  (props: ICodeInputProps, ref: ForwardedRef<any>) => {
    const {
      error,
      disabled = false,
      hidden,
      isErrorMessageDisabled,
      isErrorIconDisabled,
      onErrorInput,
      ...rest
    } = props;

    useEffect(() => {
      if (!isErrorMessageDisabled && error && error !== ' ' && onErrorInput) {
        onErrorInput();
      }
    }, [error]);

    return (
      <div>
        {!hidden && (
          <FieldsetContainer>
            <Fieldset error={error} isDisabled={disabled} className="textInputFieldset">
              <div className="wrapper">
                <Field
                  disabled={disabled}
                  isDisabled={disabled}
                  type="text"
                  value={props.value}
                  ref={ref}
                  {...rest}
                />
              </div>
            </Fieldset>
          </FieldsetContainer>
        )}
        {!isErrorMessageDisabled && error && error !== ' ' && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    );
  }
);

export default CodeInput;
