import {
  useState,
  ChangeEvent,
  KeyboardEvent,
  forwardRef,
  ForwardedRef,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { SelectIcon } from '@vfit/shared-icons';
import { useOnClickOutside } from '@vfit/shared/hooks';
import TextInput from '../TextInput/textInput';
import { ISearchSelectProps } from './searchSelect.models';
import { SuggestionsWrapper, Suggestions, FillBorder } from './searchSelect.style';
import Flatlist from './components/flatList';

const SearchSelect = forwardRef<HTMLInputElement, ISearchSelectProps>(
  (props: ISearchSelectProps, ref: ForwardedRef<any>) => {
    const {
      items,
      value,
      setValue,
      setError,
      clearErrors,
      trigger,
      ignoreInnerErrors,
      searchable,
      label,
      placeholder,
      isDisabled,
      isErrorMessageDisabled,
      isErrorIconDisabled,
      errorMessage,
      hidden = false,
      ...rest
    } = props;
    const [showOptions, setShowOptions] = useState<boolean>(false);

    const wrapperRef = useRef(null);
    useOnClickOutside(wrapperRef.current, () => {
      setShowOptions(false);
    });

    const toggleShowOptions = (): void => {
      if (!isDisabled) {
        setShowOptions((prev) => !prev);
      }
    };

    const getFilteredItems = useCallback(
      (text: string) => {
        if (!text) {
          return items;
        }

        const t = text.toLowerCase();

        return items
          ?.filter((s) => s.toLowerCase().indexOf(t) !== -1)
          .sort((first, second) => {
            const f = first.toLowerCase();
            const s = second.toLowerCase();

            if (f.startsWith(t) && !s.startsWith(t)) {
              return -1;
            }
            if (!f.startsWith(t) && s.startsWith(t)) {
              return 1;
            }
            if (f < s) {
              return -1;
            }
            if (f > s) {
              return 1;
            }
            return 0;
          });
      },
      [items]
    );

    const filteredItems = useMemo(
      () => (searchable ? getFilteredItems(value) : items),
      [getFilteredItems, items, searchable, value]
    );

    const showSuggestions = useMemo(
      () => filteredItems?.length > 0 && showOptions && !isDisabled,
      [filteredItems?.length, isDisabled, showOptions]
    );

    const getError = (v: string) =>
      ignoreInnerErrors
        ? ''
        : (v && getFilteredItems(v).length === 0 ? 'Nessuna opzione disponibile' : '') ||
          (v && !getFilteredItems(v).includes(v) ? 'Selezionare una voce corretta' : '');

    const triggerError = (v: string) => {
      const error = getError(v);
      if (error) {
        setError({ type: 'custom', message: error });
      } else {
        trigger();
      }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      clearErrors();
      setValue(e.target.value);
      if (!e.target.value) {
        setShowOptions(false);
      } else {
        setShowOptions(true);
        triggerError(e.target.value);
      }
    };

    const onSelect = (v: string): void => {
      clearErrors();
      setValue(v);
      setShowOptions(false);
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Backspace') {
        onSelect('');
        triggerError('');
      }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      triggerError(e.target.value);
    };

    return (
      <div>
        {!hidden && (
          <div ref={wrapperRef}>
            <TextInput
              {...rest}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              error={errorMessage}
              label={label}
              ref={ref}
              isErrorMessageDisabled={isErrorMessageDisabled || showSuggestions}
              isErrorIconDisabled={isErrorIconDisabled}
              onChange={onChange}
              onIconClick={toggleShowOptions}
              onFocus={toggleShowOptions}
              disabled={isDisabled}
              icon={<SelectIcon />}
              onBlur={handleBlur}
            />
            {showSuggestions && (
              <>
                {filteredItems.length > 0 && <FillBorder />}
                <SuggestionsWrapper>
                  <Suggestions isError={!!errorMessage && !isErrorMessageDisabled}>
                    <Flatlist
                      onSelect={onSelect}
                      items={filteredItems}
                      itemCount={filteredItems.length}
                    />
                  </Suggestions>
                </SuggestionsWrapper>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default SearchSelect;

