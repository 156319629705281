import { CustomText } from '@vfit/shared/atoms';
import { fonts } from '@vfit/shared/themes';
import { ExitIcon } from '@vfit/shared-icons';
import VfModal from '../VfModal/vfModal';
import { IListCardDevice } from './listCardDevice.model';
import { ListCardDeviceContainer, ContainerCard, Top, ShowFilterSet } from './listCardDevice.style';

const ListCardDevice = ({
  modalComponent,
  openModal,
  isOpen = false,
  title,
  labelFilter,
  children,
  filterSetLabel,
  isFiltered,
  handleRemoveFilter,
  refContainer,
}: IListCardDevice) => (
  <>
    <ListCardDeviceContainer>
      {title && (
        <Top>
          <CustomText
            text={title || ''}
            size={36}
            sizeMobile={28}
            lineHeightMobile={36}
            lineHeight={48}
            fontFamily={fonts.exbold}
          />
          {openModal && handleRemoveFilter && (
            <>
              {!isFiltered?.isFiltered ? (
                <div onClick={() => openModal(true)}>
                  <CustomText
                    text={labelFilter || ''}
                    size={18}
                    sizeMobile={16}
                    lineHeight={24}
                    lineHeightMobile={22}
                    fontFamily={fonts.regular}
                  />
                </div>
              ) : (
                <ShowFilterSet>
                  <p>{filterSetLabel}</p>
                  <p className="filter">{isFiltered.filteredBy}</p>
                  <div onClick={() => handleRemoveFilter()}>
                    <ExitIcon />
                  </div>
                </ShowFilterSet>
              )}
            </>
          )}
        </Top>
      )}
      <ContainerCard ref={refContainer}>{children}</ContainerCard>
    </ListCardDeviceContainer>
    {modalComponent && openModal && (
      <VfModal height={813} isOpen={isOpen} handleClose={() => openModal(false)}>
        {modalComponent}
      </VfModal>
    )}
  </>
);

export default ListCardDevice;
