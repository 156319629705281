import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFont, colors } from '@vfit/shared/themes';

export const Container = styled.div`
  margin-bottom: 120px;
`;

export const MainContainer = styled.div`
  margin: 0 auto;
  background-color: ${colors.$ffffff};
  max-width: 327px;

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 680px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-width: 1062px;
  }
`;

export const CardContainer = styled.div<{ isLastCard: boolean }>`
  display: flex;
  border: 1px solid ${colors.$bebebe};
  border-radius: 20px;
  justify-content: space-between;
  margin-bottom: ${({ isLastCard }) => (isLastCard ? 0 : '48px')};

  @media (min-width: ${breakpoints.tablet}) {
    align-items: center;
    height: 441px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: 458px;
  }

  img {
    height: inherit;
    object-fit: cover;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    @media (min-width: ${breakpoints.tablet}) {
      margin-left: 32px;
      width: 328px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      margin-left: 44px;
      width: 531px !important;
    }
  }
`;

export const DescriptionBox = styled.div`
  padding: 32px 24px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 40px 0;
    padding-left: 24px;
    width: 296px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-left: 64px;
    width: 403px;
  }
`;

export const Title = styled.p`
  margin: 0;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)}

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Description = styled.div`
  div {
    margin: 0;
    margin-top: 24px;
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFont(18, 24)}
    color: ${colors.$262626};
    text-align: left;

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(18, 24)}
    }
  }
`;

export const CtaContainer = styled.div`
  margin-top: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    display: block;
  }

  @media (min-width: ${breakpoints.desktop}) {
    display: inline-block;
  }

  button,
  a {
    width: 100%;

    @media (min-width: ${breakpoints.tablet}) {
      width: 258px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 296px;
    }
  }
`;

export const Info = styled.div`
  margin-top: 44px;

  div {
    text-align: left;
    margin: 0;
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFont(14, 18)}
    color: #666;

    a {
      ${pxToCssFont(16, 22)}
    }
  }
`;

export const TitleContainer = styled.div`
  div {
    margin-bottom: 16px;
    p {
      margin: 0;
    }
  }
`;

export const DescriptionContainer = styled.div`
  div {
    ${pxToCssFont(18, 24)}
    font-family: ${fonts.regular};
    margin-bottom: 48px;
    margin-top: 0;
    p {
      margin: 0;
    }
  }
`;

