import { useState } from 'react';
import { ContainerTopProduct, ImageDivider, CustomText, ButtonSlide } from '@vfit/shared/atoms';
import { Fade } from 'react-awesome-reveal';
import { VfModal } from '@vfit/shared/components';
import { fonts } from '@vfit/shared/themes';
import { ActionTextType, ITextModal } from '@vfit/shared/models';
import { IText } from './text.models';
import { DisclaimerContainer, ModalContent, ModalTitle, ButtonContainer } from './text.style';

const Text = ({ textConfig, divider, disclaimer, textRef, actionText, actionClick }: IText) => {
  const [modal, setModal] = useState<ITextModal>({
    show: false,
  });
  const onClickTypeInText = (
    type: ActionTextType,
    description: string | undefined,
    title?: string
  ) => {
    if (description) {
      setModal({
        show: true,
        text: description || '',
        title: title || '',
        disableOrganizeText: true,
      });
    }
  };

  const resetModal = () => {
    setModal({
      show: false,
      text: '',
      title: '',
      disableOrganizeText: false,
    });
  };

  return (
    <>
      <ContainerTopProduct ref={textRef}>
        <Fade direction="up" triggerOnce>
          <CustomText
            textAlign="left"
            onClickType={onClickTypeInText}
            size={52}
            lineHeight={70}
            sizeMobile={35}
            lineHeightMobile={45}
            {...textConfig}
          />
          {actionText && actionClick && (
            <ButtonContainer>
              <ButtonSlide
                label={actionText}
                onClick={actionClick}
                buttonColor="#e60000"
                labelColor="#fff"
                hoverColor="#bd0000"
                clickColor="#a10000"
                name="action_text"
              />
            </ButtonContainer>
          )}
          {disclaimer && (
            <DisclaimerContainer>
              <CustomText
                textAlign="left"
                text={disclaimer}
                lineHeight={22}
                size={16}
                fontFamily={fonts.regular}
              />
            </DisclaimerContainer>
          )}
        </Fade>
      </ContainerTopProduct>
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider image={divider?.image} alt={divider.text || ''} />
        </Fade>
      )}
      <VfModal height={563} isOpen={modal.show} handleClose={resetModal} customFooter={undefined}>
        <>
          {modal.title && (
            <ModalTitle>
              <CustomText
                fontFamily={fonts.exbold}
                size={36}
                lineHeight={45}
                textAlign="left"
                text={modal.title}
              />
            </ModalTitle>
          )}
          {modal.text && (
            <ModalContent>
              <CustomText
                size={18}
                lineHeight={24}
                textAlign="left"
                text={modal.text}
                disableOrganizeText={modal.disableOrganizeText}
              />
            </ModalContent>
          )}
        </>
      </VfModal>
    </>
  );
};

export default Text;
