import { Button, CustomText, ImageAtoms } from '@vfit/shared/atoms';
import {
  AppMobile,
  getButtonActionByActionType,
  getVariantByActionStyle,
} from '@vfit/shared/data-access';
import { colors, fonts } from '@vfit/shared/themes';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import { IPassionCard } from './passionCard.models';
import {
  PassionAction,
  PassionCardBottom,
  PassionCardBottomContainer,
  PassionCardBottomContainerText,
  PassionCardContainer,
  PassionCardTopImage,
  PassionCardTopImageJiustifyContentBox,
  TextContainer,
  TitleAboveImg,
} from './passionCard.style';
import Pills from '../Pills/pills';

const PassionCard = ({
  title,
  image,
  description,
  action,
  titleAboveImg,
  pills,
  customTracking,
  external,
  actionNavigationUrl,
}: IPassionCard) => {
  const { push } = useRouter();
  const queryClient = useQueryClient();

  return (
    <PassionCardContainer data-name='passion-card-container' isApp={AppMobile.checkIsApp()}>
      <PassionCardTopImage isApp={AppMobile.checkIsApp()}>
        <ImageAtoms nameCard="passionCard" image={image} external={external} key={image} />
        {(pills || titleAboveImg) && (
          <PassionCardTopImageJiustifyContentBox>
            {pills && (
              <Pills
                pills={pills.pills?.map((el) => el.keyword) || []}
                variant={pills.isDark === 'true' ? colors.$262626 : colors.$ffffff}
              />
            )}
            {titleAboveImg && <TitleAboveImg>{titleAboveImg}</TitleAboveImg>}
          </PassionCardTopImageJiustifyContentBox>
        )}
      </PassionCardTopImage>
      <PassionCardBottom className="bottom-container">
        <PassionCardBottomContainer>
          <PassionCardBottomContainerText>
            <h3>{title}</h3>
            {description && (
              <TextContainer lineHeight={24}>
                <CustomText fontFamily={fonts.regular} text={description} textAlign="left" />
              </TextContainer>
            )}
          </PassionCardBottomContainerText>
        </PassionCardBottomContainer>
        {action?.title && action?.type && (
          <PassionAction>
            <Button
              onClick={() => {
                customTracking?.();
                // actionClick is inhibited to avoid double url opening
                !actionNavigationUrl && getButtonActionByActionType(action, push, queryClient);
              }}
              href={actionNavigationUrl}
              target={actionNavigationUrl && action?.isBlank === 'true' ? '_blank' : undefined}
              name={`action_pCard_${action?.type?.toString() || ''}`}
              variant={getVariantByActionStyle(action.style)}
            >
              {action.title}
            </Button>
          </PassionAction>
        )}
      </PassionCardBottom>
    </PassionCardContainer>
  );
};
export default PassionCard;
