import { ImageDividerContainer } from './imageDivider.style';
import { IImageDividerProps } from './imageDivider.models';
// eslint-disable-next-line import/no-cycle
import { ImageAtoms } from '../..';

const ImageDivider = ({ image, imageMobile, isMobile, alt }: IImageDividerProps) => {
  const isGif = isMobile
    ? (imageMobile || image)?.split('.')[1] === 'gif'
    : image?.split('.')[1] === 'gif';
  return (
    <ImageDividerContainer enableMaxWidth={isGif}>
      <ImageAtoms
        nameCard="divider"
        image={image}
        imageMobile={imageMobile}
        isMobile={isMobile}
        alt={alt}
      />
    </ImageDividerContainer>
  );
};
export default ImageDivider;
