import { colors } from '@vfit/shared/themes';
import styled from 'styled-components';

export const FillBorder = styled.div`
  height: 3px;
  background: transparent;
  border-left: 1px solid ${colors.$bebebe};
  border-right: 1px solid ${colors.$bebebe};
  margin-top: -3px;
`;

export const SuggestionsWrapper = styled.div`
  position: relative;
  background-color: ${colors.$ffffff};
  border-radius: 6px;
`;

export const Suggestions = styled.ul<{ isError: boolean }>`
  z-index: 3;
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: 0;
  width: 100%;
  width: fill-available;
  background-color: ${colors.$ffffff};
  padding: 0;
  border-left: 1px solid ${colors.$bebebe};
  border-right: 1px solid ${colors.$bebebe};
  border-bottom: 1px solid ${colors.$bebebe};
  border-radius: 0 0 5px 5px;

  > div::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  > div::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 10px;
  }

  > div::-webkit-scrollbar-track {
    background: ${colors.$ffffff};
    border-radius: 10px;
  }
`;

