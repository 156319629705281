import { breakpoints, colors, fonts } from '@vfit/shared/themes';
import styled from 'styled-components';

export const ListCardDeviceContainer = styled.div`

`;

export const Top = styled.div`
  margin: 32px 23px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  gap: 16px;
 

  div{
    margin: 0;
    p{
      margin: 0;
    }
  }


  @media (min-width: ${breakpoints.desktop}) {
    margin: 48px 80px;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
  }
`;

export const ContainerCard = styled.div`
  margin: 0 23px 0 23px;
  display: flex;
  justify-content: center;
  
  flex-wrap: wrap;
  gap: 20px;

  @media (min-width: ${breakpoints.desktop}) {
    margin: 0 80px;
    justify-content: flex-start;
    gap: 84px 32px;
  }
`;

export const ShowFilterSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 6px 16px;
  background-color:  ${colors.$f2f2f2};
  border-radius: 20px;

  p {
    margin: 0;
    line-height: 24px;
    font-size: 18px;
    font-family: ${fonts.regular};
  }

  .filter {
    font-weight: 700;
    margin-right: 8px;
  }
`;


