import React from 'react';

const observeHeight = (ref: React.MutableRefObject<HTMLDivElement>): void => {
  const body = document.getElementsByTagName('body')?.[0];
  if (!body) return;
  const bodyWidth = body.getBoundingClientRect()?.width;
  const bodyHeight = body.getBoundingClientRect()?.height;
  let wrapperNote = null;
  body.childNodes.forEach((note) => {
    const noteElement = note as HTMLElement;
    if (noteElement.clientWidth === bodyWidth && noteElement.clientHeight === bodyHeight) {
      wrapperNote = note;
    }
  });
  wrapperNote &&
    (wrapperNote as HTMLElement)?.childNodes.forEach((note) => {
      const noteElement = note as HTMLElement;
      if (noteElement.clientWidth === bodyWidth && noteElement.clientHeight >= bodyHeight) {
        noteElement.style.paddingBottom = `${ref.current.clientHeight}px`;
      }
    });
};

export { observeHeight };
