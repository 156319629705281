import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';
import { ICSSProps } from './textInput.models';

export const ErrorMessage = styled.p`
  position: relative;
  animation: fadein 2s;
  margin-block: 0;
  margin-inline: 0;
  margin-bottom: -2px !important;
  color: ${colors.$e60000};
  ${pxToCssFont(12, 24)}
  font-family: ${fonts.regular};
  text-align: left;

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: -8px !important;
  }
`;

export const Fieldset = styled.div<ICSSProps>`
  position: relative;
  margin: 0;
  padding: 18px 16px 18px 20px;
  display: flex;
  transition: border 2s;
  border-radius: 5.008px;
  background: transparent;
  ${(props) => {
    if (props.error) {
      return `border: 1px solid ${colors.$e60000}`;
    }
    return props.isDisabled ? `border: 1px solid #BEBEBE` : `border: 1px solid #BEBEBE`;
  }};

  .wrapper {
    display: inline-flex;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 22px;
  }

  .wrapper-icon {
    display: flex;

    @media (min-width: ${breakpoints.mobile}) {
      align-self: center;
    }

    @media (min-width: ${breakpoints.tablet}) {
      align-self: center;
    }
  }

  .icon {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    appearance: none;
    background: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    padding: 0;

    &:hover {
      cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    }
  }

  ${(props) => (props.isDisabled ? '&:hover {cursor: not-allowed}' : '')}
`;

// div for inputs
export const Field = styled.input<ICSSProps>`
  background-color: transparent;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 22px;
  outline: 0;
  border: 0;
  text-overflow: ellipsis;
  font-family: ${fonts.regular};
  ${pxToCssFontSize(18)}
  text-align: left;

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }

  &:read-only {
    color: ${colors.$bebebe};
    background-color: transparent;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.$262626};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: auto;
  }
`;

export const Legend = styled.label<ICSSProps>`
  color: ${colors.$7e7e7e};
  height: 18px;
  padding: 0 8px;
  margin-left: -5.008px;
  background: white;
  z-index: -2;
  ${pxToCssFontSize(18)}
  font-family: ${fonts.regular};
  text-align: left;
  position: absolute;
  transition: 0.2s;

  ${Field}:not(:placeholder-shown) ~ &,
  ${Field}:focus ~ & {
    top: -10px;
    ${pxToCssFont(14, 18)}
    z-index: 2;
    color: ${colors.$262626};
  }
`;
