import React, { Children } from 'react';
import { AppMobile, getVariantByActionStyle } from '@vfit/shared/data-access';
import { Accordion, ImageDivider, CustomText, Button } from '@vfit/shared/atoms';
import { fonts } from '@vfit/shared/themes';
import { Fade } from 'react-awesome-reveal';
import {
  FaqAccordionWrapper,
  FaqContainer,
  FaqShowMore,
  Text,
  DisclaimerContainer,
} from './faq.style';
import { IFaqWidget } from './faq.models';

const Faq = ({
  faq,
  containerStyle,
  topText,
  divider,
  disclaimer,
  handleButtonClick,
  actionNavigationUrl,
}: IFaqWidget) => (
  <>
    {topText && (
      <Text>
        <CustomText text={topText} />
      </Text>
    )}
    <FaqContainer style={{ ...containerStyle }}>
      {Children.toArray(
        faq.elements?.map((f) => (
          <FaqAccordionWrapper>
            <Accordion
              title={f.title || ''}
              iconColor="#e60000"
              iconSize={24}
              content={[
                <CustomText
                  textAlign="left"
                  text={f.description || ''}
                  sizeMobile={16}
                  lineHeightMobile={22}
                  size={16}
                  lineHeight={22}
                  fontFamily={fonts.regular}
                />,
              ]}
            />
          </FaqAccordionWrapper>
        ))
      )}
      {faq.showMore?.title && (
        <FaqShowMore>
          <Button
            // handleButtonClick is inhibited to avoid double url opening
            onClick={() => !actionNavigationUrl && handleButtonClick?.()}
            href={actionNavigationUrl}
            target={actionNavigationUrl && faq.showMore?.isBlank === 'true' ? '_blank' : undefined}
            name={`action_showMore_${faq.showMore?.type}`}
            variant={
              AppMobile.checkIsApp()
                ? 'mva:alt1'
                : getVariantByActionStyle(faq.showMore?.style)
            }
          >
            <span className={`${AppMobile.checkIsApp() ? 'capitalize-first' : ''}`}>
              {faq.showMore?.title}
            </span>
          </Button>
        </FaqShowMore>
      )}
    </FaqContainer>
    {disclaimer && (
      <DisclaimerContainer>
        <CustomText text={disclaimer} lineHeight={22} size={16} textAlign="left" />
      </DisclaimerContainer>
    )}
    {divider?.image && (
      <Fade direction="up" triggerOnce>
        <ImageDivider image={divider?.image} alt={divider.text || ''} />
      </Fade>
    )}
  </>
);

export default Faq;

