import { colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const FillBorder = styled.div`
  height: 20px;
  background: transparent;
  border-color: ${colors.$bebebe};
  border-style: solid;
  border-width: 0 1px;
  margin-top: -10px;
`;

export const Wrapper = styled.div`
  position: relative;
  border-radius: 6px;
  background-color: ${colors.$ffffff};
  z-index: 3;
`;

export const Suggestions = styled.ul`
  z-index: 3;
  max-height: 258px;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  margin-top: -10px;
  width: 100%;
  width: fill-available;
  background-color: ${colors.$ffffff};
  border-radius: 0 0 6px 6px;
  padding: 0;
  border-left: 1px solid ${colors.$bebebe};
  border-right: 1px solid ${colors.$bebebe};
  border-bottom: 1px solid ${colors.$bebebe};

  ::-moz-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  li:first-child {
    padding-top: 16px;
  }

  li:last-child {
    padding-bottom: 16px;
  }
`;

export const SuggestionItem = styled.li`
  list-style: none;
  padding: 8px 20px;
  color: ${colors.$7e7e7e};
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(18, 24)}
  text-align: left;

  &:hover {
    cursor: pointer;
    background-color: #ccc;
  }
`;

export const SuggestionItemPlaceholder = styled.li`
  list-style: none;
  padding: 8px 20px;
  color: ${colors.$0d0d0d};
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFont(18, 24)}
  text-align: left;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    background-color: #ccc;
  }
`;

