import { CustomText } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { fonts } from '@vfit/shared/themes';
import { MainContainer, TableContainer, TextContainer } from './splittedTableText.style';
import { ISplittedTableText } from './splittedTableText.models';
import CardTable from './components/CardTable/cardTable';

const SplittedTableText = ({
  title,
  description,
  tableRows,
  action,
  handleButtonClick,
  actionNavigationUrl,
}: ISplittedTableText) => {
  const { isMobile } = useDeviceType();
  return (
    <MainContainer>
      <TextContainer>
        <CustomText
          text={title}
          fontFamily={fonts.exbold}
          size={36}
          sizeMobile={28}
          lineHeight={48}
          lineHeightMobile={32}
          textAlign={isMobile ? 'center' : 'left'}
        />
        <CustomText
          text={description}
          size={22}
          sizeMobile={20}
          lineHeight={36}
          lineHeightMobile={28}
          textAlign={isMobile ? 'center' : 'left'}
        />
      </TextContainer>
      <TableContainer>
        <CardTable
          tableRows={tableRows}
          action={action}
          actionClick={() => handleButtonClick && handleButtonClick(action)}
          actionNavigationUrl={actionNavigationUrl}
        />
      </TableContainer>
    </MainContainer>
  );
};

export default SplittedTableText;

