import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Button, ButtonSlide, ScrollableIndicator } from '@vfit/shared/atoms';
import { Arrow } from '@vfit/shared-icons';
import { useDeviceType, useSwipeElement } from '@vfit/shared/hooks';
import { purify } from '@vfit/shared/themes';
import { checkWindow, AppMobile } from '@vfit/shared/data-access';
import { checkIsApp } from '@vfit/consumer/data-access';
import * as S from './stickyOffer.style';
import { IShowDetails, IStickyOffer } from './stickyOffer.model';
import { observeHeight } from './stickyOffer.utils';

const StickyOffer: React.FC<IStickyOffer> = ({
  topLabel,
  title,
  id,
  description,
  ctaActivation,
  content,
  withProgressbar,
  getContentHeight,
  isLoading,
  slideBanner,
  hideDetailLabel,
  showDetailLabel,
}) => {
  const [pTitle, pTopLabel] = purify([title, topLabel]);
  const [showDetails, setShowDetails] = useState<IShowDetails>('');
  const [activationBoxHeight, setActivationBoxHeight] = useState(0);
  const [ctaContainerWidth, setCtaContainerWidth] = useState(0);
  const activationBoxRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const ctaContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { isDesktop } = useDeviceType();
  const [tmpSlideBanner, setTmpSlideBanner] = useState(slideBanner);

  const observer = useRef(
    new ResizeObserver((entries) => {
      const { bottom, top } = entries[0].contentRect;
      const boxHeight = bottom + top;
      setActivationBoxHeight(boxHeight);

      // eslint-disable-next-line no-unsafe-optional-chaining
      setCtaContainerWidth(ctaContainerRef?.current?.clientWidth * 2 + 160);
      if (getContentHeight) {
        getContentHeight(boxHeight);
      }
    })
  );

  const stepProgress = useMemo(() => {
    if (withProgressbar?.show && withProgressbar.steps > 0) {
      return (withProgressbar.currentStep / withProgressbar.steps) * 100;
    }
    return 0;
  }, [withProgressbar]);

  useEffect(() => {
    const observerRef = observer.current;
    if (activationBoxRef && observerRef) {
      observerRef.observe(activationBoxRef.current);
    }
    return () => {
      if (observerRef) observerRef.disconnect();
    };
  }, [activationBoxRef, observer]);

  useEffect(() => {
    setTmpSlideBanner(slideBanner);
  }, [slideBanner]);

  const isApp = AppMobile.checkIsApp();

  const { onTouchStart, onTouchMove, onTouchEnd, swipeStatus } = useSwipeElement();

  useEffect(() => {
    if (checkWindow()) {
      observeHeight(activationBoxRef);
    }
  }, []);

  useEffect(() => {
    if (isApp) {
      if (swipeStatus === 'up') setShowDetails('slide-up');
      if (swipeStatus === 'down') setShowDetails('slide-down');
    }
  }, [swipeStatus, isDesktop]);

  const toggleDetails = useCallback(() => {
    if (showDetails !== 'slide-up') {
      setTmpSlideBanner(undefined);
      setShowDetails('slide-up');
    } else {
      setShowDetails('slide-down');
    }
  }, [showDetails]);

  const DetailsController = useMemo(() => {
    if (isLoading) {
      return <S.DetailsLoading />;
    }
    return (
      <S.Details isApp={checkIsApp()}>
        <div>
          {pTopLabel && (
            <S.TopLabel
              className="topLabel"
              dangerouslySetInnerHTML={{
                __html: pTopLabel,
              }}
            />
          )}
          {pTitle && (
            <p
              dangerouslySetInnerHTML={{
                __html: pTitle,
              }}
            />
          )}
        </div>
        {content && description && !isDesktop && (
          <S.ShowDetailsCta
            rotation={showDetails === 'slide-up' ? '-90' : '90'}
            onClick={toggleDetails}
          >
            <p>{description}</p>
            <Arrow />
          </S.ShowDetailsCta>
        )}
        {content && isDesktop && (
          <ButtonSlide
            label={
              showDetails !== 'slide-up'
                ? showDetailLabel || 'SCOPRI DETTAGLI'
                : hideDetailLabel || 'NASCONDI DETTAGLI'
            }
            onClick={toggleDetails}
            borderColor="#0d0d0d"
            isApp={AppMobile.checkIsApp()}
            hoverColor="#262626"
            hoverTextColor="#fff"
            clickColor="#7e7e7e"
            clickTextColor="#fff"
          />
        )}
      </S.Details>
    );
  }, [content, description, isDesktop, isLoading, showDetails, title, toggleDetails]);

  return (
    <>
      <S.OpaqueBackground
        id="opaque-background"
        isActive={!AppMobile.checkIsApp() ? '' : showDetails}
        onClick={() => setShowDetails('slide-down')}
      />
      <S.MainContainer
        id={id}
        isApp={AppMobile.checkIsApp()}
        activationBoxHeight={activationBoxHeight}
        className={`${showDetails} ${tmpSlideBanner || ''}`}
      >
        <S.ActivationBox
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          ref={activationBoxRef}
          withProgressbar={withProgressbar?.show}
        >
          {withProgressbar?.show && showDetails !== 'slide-up' && (
            <S.ProgressbarContainer>
              <S.Bar stepCoverage={stepProgress} />
            </S.ProgressbarContainer>
          )}
          {AppMobile.checkIsApp() && <ScrollableIndicator />}
          {DetailsController}
          <S.CtaContainer ref={ctaContainerRef} id="sticky-offer-next">
            {ctaActivation?.label && (
              <Button
                disabled={ctaActivation?.variant ? false : ctaActivation?.isDisabled || isLoading}
                onClick={() => {
                  if (showDetails === 'slide-up') setShowDetails('slide-down');
                  // actionClick is inhibited to avoid double url opening
                  !ctaActivation?.actionNavigationUrl && ctaActivation?.action?.();
                }}
                href={ctaActivation?.actionNavigationUrl}
                target={
                  ctaActivation?.actionNavigationUrl && ctaActivation?.isBlank === 'true'
                    ? '_blank'
                    : undefined
                }
                name={ctaActivation?.type ? `action_StickyOffer_${ctaActivation?.type}` : undefined}
                variant={
                  checkIsApp()
                    ? ctaActivation?.variant || 'mva:primary'
                    : ctaActivation?.variant || 'primary'
                }
              >
                {ctaActivation?.label}
              </Button>
            )}
          </S.CtaContainer>
        </S.ActivationBox>
        {content && showDetails === 'slide-up' && (
          <S.ChildrenContainer
            activationBoxHeight={activationBoxHeight}
            ctaContainerWidth={ctaContainerWidth}
          >
            <S.OverlayGlobalStyle />
            {content}
          </S.ChildrenContainer>
        )}
      </S.MainContainer>
    </>
  );
};

export default StickyOffer;
