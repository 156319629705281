import { useEffect } from 'react';

export const useOnClickOutside = (el: HTMLElement | null, handler: () => void) => {
  useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent) => {
      if (!el.contains(e.target as Node | null) || !e.composedPath().includes(el as Node | null)) {
        handler();
      }
    };

    if (el) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [el, handler]);
};

