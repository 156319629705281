/* eslint-disable react-hooks/exhaustive-deps */
import { Children, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import { useDeviceType } from '@vfit/shared/hooks';
import { Arrow } from '@vfit/shared-icons';
import { LoggerInstance } from '@vfit/shared/data-access';
import { ImageAtoms } from '@vfit/shared/atoms';
import { ShadowsGroup, StickyOffer } from '@vfit/shared/components';
import { Player } from '@lottiefiles/react-lottie-player';
import { requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import {
  IGradientImage,
  ICardDirectionType,
  IMainCardStyle,
  INextStep,
  IStepCard,
  IStepperCardsProps,
  IBackStyle,
} from '@vfit/shared/models';
import {
  DefaultBackStyle,
  IntersectedBackStyle,
  StepperCardList,
  StepperCardListContainer,
  StepperCardListItem,
  StepperCardNavigation,
  StepperCardsBlurred,
  StepperCardsContainer,
  DivImgContainer,
  Skeleton,
} from './stepperStateCards.style';
import IntersectiveSpace from './components/IntersectiveSpace/intersectiveSpace';
import { getCardStyle, getResizedSteps, organizeSlide } from './stepperStateCards.utils';

const StepperStateCards = forwardRef((props: IStepperCardsProps, stepperRef) => {
  const {
    isLoadingContainer = false,
    isLoadingStickyOffer = false,
    isForcedDisableCtaActivation = false,
    steps,
    onBack,
    onClose,
    enableStickyStepper,
    titleStickyStepper,
    topLabelStickyStepper,
    contentStickyStepper,
    actionLabelStickyStepper,
    nextLabelStickyStepper,
    variantNextLabelStickyStepper,
    blurredImage = '',
    onFinish: onFinishSteps,
    disableBack,
    hideBackButton,
    forceFinish,
    visibilityModal = true,
    lottieLoader,
    hideInternalComponents = false,
    onActiveSlideChange,
  } = props;

  const { isMobile, isTablet, isDesktop } = useDeviceType();
  const [transformThis, setTransformThis] = useState(false);
  const [transformPrev, setTransformPrev] = useState(false);
  const [buttonBackTextStyle, setButtonBackTextStyle] = useState<IBackStyle>(DefaultBackStyle);
  const [buttonBackArrowStyle, setButtonBackArrowStyle] = useState<IBackStyle>(DefaultBackStyle);
  const [isTextIntersected, setIsTextIntersected] = useState<boolean>(false);
  const [showBackAnimation, setShowBackAnimation] = useState(disableBack || false);
  const [allSteps, setAllSteps] = useState<IStepCard[]>(steps);
  const [activeSlide, setActiveSlide] = useState(0);
  const [mainCardStyle, setMainCardStyle] = useState<IMainCardStyle>(
    getCardStyle(isMobile, isTablet, isDesktop)
  );
  const [stateSteps, setStateSteps] = useState<IStepCard[]>(
    organizeSlide(ICardDirectionType.NONE, steps, [], 0, mainCardStyle)
  );
  const [stickyOfferHeight, setStickyOfferHeight] = useState<number>(0);
  const organizeDynamicSteps = (updatedSteps: IStepCard[]) => steps.map((s) => {
    const foundedStep = updatedSteps.find((aS) => aS.title === s.title);
    if (foundedStep) {
      return {
        ...foundedStep,
        ...s,
      };
    }
    return s;
  });
  // On change steps for dynamic inject component
  useEffect(() => {
    setAllSteps((updatedSteps) => organizeDynamicSteps(updatedSteps));
    setTimeout(() => {
      setShowBackAnimation(!disableBack);
    }, 400);
  }, [steps]);

  useEffect(() => {
    if (mainCardStyle) {
      setStateSteps(getResizedSteps(stateSteps, mainCardStyle));
    }
  }, [mainCardStyle]);

  useEffect(() => {
    if (isMobile || isDesktop || isTablet) {
      setMainCardStyle(getCardStyle(isMobile, isTablet, isDesktop));
    }
  }, [isMobile, isTablet, isDesktop]);

  const getUpdatedAllStateComponent = (
    nextStep?: INextStep,
    enabledGoNext?: boolean
  ): IStepCard[] =>
    allSteps.map((aS, indexAllStep) => {
      if (indexAllStep === activeSlide) {
        return {
          ...aS,
          enableGoNext: enabledGoNext || undefined,
        };
      }
      if (nextStep?.component || indexAllStep === activeSlide + 1) {
        return {
          ...aS,
          ...(indexAllStep === activeSlide + 1 &&
            nextStep?.component && { component: nextStep.component }),
        };
      }
      return aS;
    });

  const onFinish = (handleOnFinishState?: () => void) => {
    if (handleOnFinishState) {
      handleOnFinishState();
    } else if (onFinishSteps) onFinishSteps();
    else LoggerInstance.debug('Final Step');
  };

  const onNext = (
    nextStep?: INextStep,
    forceFinishOnNext?: boolean,
    handleOnFinish?: () => void
  ) => {
    const allStateComponent = getUpdatedAllStateComponent(nextStep);
    setAllSteps(allStateComponent);
    const isLast = activeSlide === allSteps.length - 1;
    if (isLast || forceFinishOnNext) {
      onFinish(handleOnFinish);
      return;
    }
    const currentSlideIndex = activeSlide + 1;
    setTransformThis(true);
    setActiveSlide(currentSlideIndex);
    onActiveSlideChange?.(currentSlideIndex);
    setTimeout(() => {
      setTransformThis(false);
      setStateSteps(
        organizeSlide(
          ICardDirectionType.NEXT,
          allStateComponent,
          stateSteps,
          currentSlideIndex,
          mainCardStyle
        )
      );
    }, 400);
  };

  const onPrev = () => {
    if (activeSlide <= 0) {
      if (onBack) onBack();
      return;
    }
    if (activeSlide === 0) {
      return;
    }
    const currentSlideIndex = activeSlide - 1;
    setTransformPrev(true);
    setStateSteps(
      organizeSlide(ICardDirectionType.PREV, allSteps, stateSteps, currentSlideIndex, mainCardStyle)
    );
    setActiveSlide(currentSlideIndex);
    onActiveSlideChange?.(currentSlideIndex);
    setTimeout(() => {
      setTransformPrev(false);
    }, 400);
  };

  const onChangeEnableGoNext = (handleEnableGoNext?: boolean, nextStep?: INextStep) => {
    setAllSteps(getUpdatedAllStateComponent(nextStep, handleEnableGoNext));
  };

  const handleGoNextStickyFooter = () => {
    const showPopup =
      document?.querySelector('#sticky-offer-next')?.getAttribute('data-popup') === 'true';
    if (showPopup) return;
    if (forceFinish) {
      onFinish();
      return;
    }
    onNext();
  };

  const updateButtonTextStyle = (isIntersercted: false) => {
    setButtonBackTextStyle(isIntersercted ? IntersectedBackStyle : DefaultBackStyle);
    setIsTextIntersected(isIntersercted);
  };

  const updateButtonArrowStyle = (isIntersercted: false) => {
    setButtonBackArrowStyle(isIntersercted ? IntersectedBackStyle : DefaultBackStyle);
  };

  const checkIsDisabledCtaActivation = () => {
    if (isForcedDisableCtaActivation) return true;
    return allSteps.length > 0 ? !allSteps[activeSlide]?.enableGoNext : true;
  };

  useImperativeHandle(stepperRef, () => ({
    _stepperGoNext(
      nextStep?: INextStep,
      forceFinishImperativeHandle?: boolean,
      handleOnFinish?: () => void
    ) {
      onNext(nextStep, forceFinishImperativeHandle, handleOnFinish);
    },
    _stepperGoPrev() {
      onPrev();
    },
    _getActiveSlideIndex() {
      return activeSlide;
    },
    _setActiveSlideIndex(activeSlideIndex: number) {
      setActiveSlide(activeSlideIndex);
    },
  }));

  const renderBackButton = () => {
    if (!showBackAnimation) return <div />;
    let BackButtonComponent = (
      <StepperCardNavigation
        id="back-button"
        onClick={(event) => {
          if (event.target === event.currentTarget && isTextIntersected) {
            return;
          }
          onPrev();
        }}
      >
        <div style={{ ...buttonBackArrowStyle }}>
          <Arrow rotate={130} stroke={buttonBackArrowStyle['color']} />
        </div>
        <span style={{ ...buttonBackTextStyle }}>Indietro</span>
      </StepperCardNavigation>
    );
    if (hideBackButton) BackButtonComponent = <div />;
    return (
      <Fade triggerOnce style={{ position: 'absolute', zIndex: 1 }}>
        {BackButtonComponent}
      </Fade>
    );
  };

  return (
    <StepperCardsBlurred>
      <DivImgContainer>
        <ImageAtoms
          gradient={IGradientImage.GRADIENT_FULL}
          nameCard="stepperImgBlurred"
          image={blurredImage}
          isMobile={isMobile}
        />
      </DivImgContainer>
      {renderBackButton()}
      {stateSteps && stateSteps.length > 0 && (
        <Slide direction="up" triggerOnce>
          <StepperCardsContainer
            id="stepper-cards-container"
            style={{ visibility: visibilityModal ? 'inherit' : 'hidden' }}
            footerHeight={stickyOfferHeight}
          >
            <StepperCardListContainer>
              <StepperCardList>
                {Children.toArray(
                  stateSteps.map((aS, indexStep) => {
                    const { top, width, height, opacity, zIndex } = aS.cardStyle || {};
                    const isLastEl = indexStep === stateSteps.length - 1;
                    const ContentComponent = aS.component;
                    return (
                      <StepperCardListItem
                        transformThis={isLastEl ? transformThis : false}
                        transformPrev={false}
                        width={width || 0}
                        height={height || 0}
                        top={top || 0}
                        zIndex={zIndex || 0}
                        opacity={opacity !== undefined ? opacity : 1}
                      >
                        {isLoadingContainer && (
                          <Skeleton height={height || 0}>
                            {lottieLoader && (
                              <Player
                                src={requireStaticImageAtomComponent(lottieLoader).src}
                                className="player"
                                loop
                                autoplay
                              />
                            )}
                            {!lottieLoader && <ShadowsGroup quantity={2} heights={85} />}
                            <ShadowsGroup quantity={3} heights={50} />
                          </Skeleton>
                        )}
                        {indexStep === 0 && (
                          <IntersectiveSpace
                            defaultMarginBottom={40}
                            leftSpacePropertyNames={['padding-left']}
                            intersectiveCards={stateSteps}
                            intersectiveButtonId="back-button"
                            intersectivePointerId="intersective-pointer"
                            updateTextStyle={updateButtonTextStyle}
                            updateArrowStyle={updateButtonArrowStyle}
                          />
                        )}
                        {ContentComponent && !isLoadingContainer && !hideInternalComponents && (
                          <Fade delay={200}>
                            <ContentComponent
                              handleGoNextSlide={onNext}
                              handleGoPrevSlide={onPrev}
                              handleOnClose={onClose}
                              handleOnChangeEnableGoNext={onChangeEnableGoNext}
                            />
                          </Fade>
                        )}
                      </StepperCardListItem>
                    );
                  })
                )}
                {transformPrev && mainCardStyle && (
                  <StepperCardListItem
                    transformPrev={transformPrev}
                    width={mainCardStyle.THIRD.width}
                    height={mainCardStyle.THIRD.height}
                    top={mainCardStyle.THIRD.top}
                    opacity={0}
                    zIndex={4}
                  />
                )}
              </StepperCardList>
            </StepperCardListContainer>
          </StepperCardsContainer>
        </Slide>
      )}
      {enableStickyStepper && (
        <StickyOffer
          getContentHeight={(offH) => setStickyOfferHeight(offH)}
          id="sticky-offer-stepper"
          topLabel={topLabelStickyStepper}
          title={titleStickyStepper || ''}
          description={actionLabelStickyStepper || ''}
          isLoading={isLoadingStickyOffer}
          ctaActivation={{
            isDisabled: checkIsDisabledCtaActivation(),
            action: handleGoNextStickyFooter,
            label: nextLabelStickyStepper || 'Prosegui',
            ...(variantNextLabelStickyStepper && { variant: variantNextLabelStickyStepper }),
          }}
          content={contentStickyStepper || <div />}
          withProgressbar={{ show: true, steps: steps.length, currentStep: activeSlide + 1 }}
        />
      )}
    </StepperCardsBlurred>
  );
});

export default StepperStateCards;
